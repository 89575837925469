import React, { useEffect } from 'react';
import { func } from 'prop-types';
import text from 'polyglot/polyglot';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import useMenuStore from 'stores/menu';
import useTutorialStore from 'stores/tutorial';
import useSettingsStore from 'stores/settings';

import SoundOn from 'assets/svg/sound-speaker-on.svg';
import SoundOff from 'assets/svg/sound-speaker-off.svg';

import Dialogue from '../Dialogue/Dialogue';
import styles from './Step3Sound.module.scss';

const setIsMenuOpenSelector = (state) => state.setIsMenuOpen;
const soundSelector = (state) => state.settings.muted;
const speakerPositionSelector = (state) => state.speakerPosition;

const Step3Sound = ({ nextStep, closeTutorial }) => {
  const setIsMenuOpen = useMenuStore(setIsMenuOpenSelector);
  const speakerPosition = useTutorialStore(speakerPositionSelector);
  const isMute = useSettingsStore(soundSelector);
  const breakpoint = useBreakpoint();

  const desktopStyling = {
    top: speakerPosition.y || 0,
    left: speakerPosition.x || 0,
  };

  const mobileStyling = {
    top: speakerPosition.y - 160 || 0,
    left: speakerPosition.x || 0,
  };

  useEffect(() => {
    if (breakpoint) {
      if (breakpoint === DESKTOP) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoint]);

  return (
    <div
      className={styles.wrapper}
      style={{
        top: speakerPosition.y || 0,
        left: speakerPosition.x || 0,
      }}
    >
      {isMute ? (
        <span className={styles.soundAndText}>
          <SoundOff className={styles.menuSoundIcon} />
          {text.t('topPanel.soundOffText')}
        </span>
      ) : (
        <span className={styles.soundAndText}>
          <SoundOn className={styles.menuSoundIcon} />
          {text.t('topPanel.soundOnText')}
        </span>
      )}
      {isMute ? (
        <SoundOff className={styles.soundIcon} />
      ) : (
        <SoundOn className={styles.soundIcon} />
      )}
      <Dialogue
        className={styles.dialogue}
        customStyling={breakpoint === DESKTOP ? desktopStyling : mobileStyling}
        text={text.t('tutorial.step3Text')}
        nextStep={nextStep}
        closeTutorial={closeTutorial}
        hasCloseButtonLeft={breakpoint !== DESKTOP}
        hasBottomArrow={breakpoint !== DESKTOP}
        hasLeftPositionedArrow={breakpoint !== DESKTOP}
        reversedTransition={breakpoint !== DESKTOP}
      />
    </div>
  );
};

Step3Sound.propTypes = {
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
};

export default Step3Sound;
