/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { number, oneOfType, arrayOf, string, bool, object } from 'prop-types';
import clsx from 'clsx';

import shallow from 'zustand/shallow';
import text from 'polyglot/polyglot';

import { toggleArrayValue } from 'utils/helpers';
import usePurchaseStore from 'stores/purchase';
import useNumbersDrawnStore from 'stores/numbers-drawn';
import useSettingsStore from 'stores/settings';
import useNumbersMarkedStore from 'stores/numbers-marked';
import useUserStore from 'stores/user';
import useGameStore, { ACTIVE_GAME, PURCHASE_VIEW } from 'stores/game';
import useWinnerStatsStore from 'stores/winner-stats';
import useGameStatsStore from 'stores/current-game';
import useLayoutStore from 'stores/layout';

import badgeImg from 'assets/theme/images/bingoCard/badge@2x.png';
import Header from './Header/Header';
import Numbers from './Numbers/Numbers';

import styles from './BingoCard.module.scss';

const updateSelectedCardIdsSelector = (state) => [
  state.selectedCardIds,
  state.updateSelectedCardIds,
];
const numbersDrawnSelector = (state) => state.numbers;
const willAutomarkSelector = (state) => state.settings.automark;
const gameStateSelector = (state) => state.gameState;
const autosortSelector = (state) => state.settings.autosort;
const scoreboardSelector = (state) => state.scoreboard;
const maxNumberOfCardsSelector = (state) => state.currentGame.maxNumberOfCards;
const ticketsCurrentGameSelector = (state) => state.numberOfTicketsCurrentGame;
const markedNumbersSelector = (state) => [
  state.markedNumbers,
  state.addMarkedNumbers,
];
const addNewMarkedNumberItemSelector = (state) => state.addNewMarkedNumberItem;
const authenticatedUserSelector = (state) => state.user.isAuthenticated;
const isLongTicketSelector = (state) => state.isLongTickets;

const BingoCard = ({
  id,
  className,
  // cardNumber,
  numbersMap,
  footerText,
  currentSymbol,
  currentSymbolColorId,
  isSmall,
  isSmallTinyTicket,
  isMedium,
  isLarge,
  isLargeTinyTicket,
  isDemo,
  canBePurchased,
  isPurchased,
  isDisabled,
  isSelected,
  isHistory,
  hasFooter,
  winningNumbers,
  footerIsOnTop,
  isLongTicketHistoryCard,
}) => {
  const [markedNumbersStore, addMarkedNumbers] = useNumbersMarkedStore(
    markedNumbersSelector,
    shallow
  );
  const [markedNumbers, setMarkedNumbers] = useState(
    markedNumbersStore[id] ? markedNumbersStore[id] : []
  );
  const [selectedCardIds, updateSelectedCardIds] = usePurchaseStore(
    updateSelectedCardIdsSelector,
    shallow
  );
  const numbersDrawn = useNumbersDrawnStore(numbersDrawnSelector);
  const willAutomark = useSettingsStore(willAutomarkSelector);
  const gameState = useGameStore(gameStateSelector);
  const autosort = useSettingsStore(autosortSelector);
  const scoreboard = useWinnerStatsStore(scoreboardSelector);
  const maxNumberOfCards = useGameStatsStore(maxNumberOfCardsSelector);
  const boughtTickets = usePurchaseStore(ticketsCurrentGameSelector);
  const authenticatedUser = useUserStore(authenticatedUserSelector);
  const isLongTicket = useLayoutStore(isLongTicketSelector);
  const addNewMarkedNumberItem = useNumbersMarkedStore(
    addNewMarkedNumberItemSelector
  );
  const isActiveGame = gameState === ACTIVE_GAME;
  const isPurchase = gameState === PURCHASE_VIEW;

  useEffect(() => {
    if (!markedNumbersStore[id] && isPurchased && isActiveGame) {
      addNewMarkedNumberItem(id, []);
    }
    if (markedNumbersStore[id] && markedNumbers.length) {
      addMarkedNumbers(id, markedNumbers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedNumbers]);

  const onClickNumber = (value) => {
    const updatedStore = useNumbersDrawnStore.getState();
    if (updatedStore.numbers.includes(value)) {
      setMarkedNumbers((prevState) => toggleArrayValue(prevState, value));
    }
  };

  const onClickCard = () => {
    if (!authenticatedUser) return;
    if (
      isSelected ||
      selectedCardIds.length + boughtTickets < maxNumberOfCards
    ) {
      updateSelectedCardIds(id);
    }
  };

  const renderFooterText = () => {
    if (footerText && autosort) {
      const isBingo = isNaN(footerText);
      return (
        <p className={styles.footerText}>
          {isBingo
            ? text.t('bingoCard.bingo')
            : text.t('bingoCard.numbersLeft', { numbers: footerText })}
        </p>
      );
    }
    if (isPurchased && !isActiveGame) {
      return (
        <p className={styles.footerText}>
          {text.t('bingoCard.purchase.isPurchased')}
        </p>
      );
    }

    if (canBePurchased && !isActiveGame) {
      if (isSelected) {
        return (
          <p className={styles.footerText}>
            {text.t('bingoCard.purchase.isSelected')}
          </p>
        );
      }

      return (
        <p className={styles.footerText}>
          {text.t('bingoCard.purchase.selectButton')}
        </p>
      );
    }
    return <></>;
  };

  useEffect(() => {
    // Reset global state when switching from/to ACTIVE_GAME.
    if (isPurchase) {
      setMarkedNumbers([]);
    }
  }, [isPurchase, setMarkedNumbers]);

  // Push the numbers to marked numbers for each new number.
  // This creates a cache of numbers if the user choose to switch on and off
  // automark.
  useEffect(() => {
    if ((isHistory || willAutomark) && !isPurchase) {
      setTimeout(() => {
        // Returns a unique array with Set.
        setMarkedNumbers((prevState) => [
          ...new Set([...prevState, ...numbersDrawn]),
        ]);
      }, 800);
    }
  }, [numbersDrawn, isHistory, isPurchase, willAutomark]);

  useEffect(() => {
    if (scoreboard && !willAutomark) {
      setMarkedNumbers((prevState) => [
        ...new Set([...prevState, ...numbersDrawn]),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreboard]);

  return (
    <div
      className={clsx(className, styles.wrapper, {
        [styles.isSmall]: isSmall,
        [styles.isSmallTinyTicket]: isSmallTinyTicket,
        [styles.isMedium]: isMedium,
        [styles.isLarge]: isLarge,
        [styles.isLargeTinyTicket]: isLargeTinyTicket,
        [styles.isDisabled]: isDisabled,
        [styles.canBePurchased]: canBePurchased,
        [styles.isSelected]: canBePurchased && isSelected,
        [styles.isPurchased]: isPurchased && !isActiveGame,
        [styles.isHistory]: isHistory,
        [styles.isLongTicket]: isLongTicket,
        [styles.footerIsOnTop]: footerIsOnTop,
        [styles.isLongTicketHistoryCard]: isLongTicketHistoryCard,
      })}
    >
      {canBePurchased && !isPurchased && (
        <button
          type="button"
          onClick={onClickCard}
          className={clsx(styles.overlayButton, {
            [styles.isLongTicket]: isLongTicket,
          })}
          label={
            isSelected
              ? text.t('bingoCard.purchase.isSelected')
              : text.t('bingoCard.purchase.selectButton')
          }
        />
      )}

      {isDemo && (
        <div
          className={styles.badge}
          style={{ backgroundImage: `url(${badgeImg})` }}
        >
          <span className={styles.badgeText}>{text.t('bingoCard.demo')}</span>
        </div>
      )}

      <div className={styles.inner}>
        {!isLongTicket && <Header isHistory={isHistory} />}

        <div
          className={clsx(styles.numbers, {
            [styles.isLongTicket]: isLongTicket,
          })}
        >
          <Numbers
            id={id}
            numbersMap={numbersMap}
            markedNumbers={markedNumbers}
            currentSymbol={currentSymbol}
            currentSymbolColorId={currentSymbolColorId}
            isHistory={isHistory}
            onClick={onClickNumber}
            winningNumbers={winningNumbers}
            isLongTicketHistoryCard={isLongTicketHistoryCard}
          />
        </div>
        {hasFooter && (
          <div
            className={clsx(styles.footer, {
              [styles.isCloseToBingo]:
                isActiveGame &&
                // autosort &&
                footerText &&
                (footerText === text.t('bingoCard.bingo') || footerText < 2),
              [styles.footerIsOnTop]: footerIsOnTop,
              [styles.isLongTicket]: isLongTicket,
            })}
          >
            {/* <p className={styles.cardNumber}>
              {!autosort || !ranking.length ? cardNumber : ''}
            </p> */}

            {renderFooterText()}
          </div>
        )}
      </div>
    </div>
  );
};

BingoCard.propTypes = {
  id: oneOfType([string, number]).isRequired,
  numbersMap: arrayOf(arrayOf(object)).isRequired,
  className: string,
  // cardNumber: string,
  footerText: string,
  currentSymbol: string,
  currentSymbolColorId: number,
  isSmall: bool,
  isSmallTinyTicket: bool,
  isMedium: bool,
  isLarge: bool,
  isLargeTinyTicket: bool,
  isDemo: bool,
  canBePurchased: bool,
  isPurchased: bool,
  isDisabled: bool,
  isSelected: bool,
  isHistory: bool,
  hasFooter: bool,
  winningNumbers: arrayOf(number),
  footerIsOnTop: bool,
  isLongTicketHistoryCard: bool,
};

BingoCard.defaultProps = {
  className: null,
  // cardNumber: '0',
  footerText: null,
  currentSymbol: null,
  currentSymbolColorId: null,
  isSmall: false,
  isSmallTinyTicket: false,
  isMedium: false,
  isLarge: false,
  isLargeTinyTicket: false,
  isDemo: false,
  canBePurchased: false,
  isPurchased: false,
  isDisabled: false,
  isSelected: false,
  isHistory: false,
  hasFooter: false,
  winningNumbers: null,
  footerIsOnTop: false,
  isLongTicketHistoryCard: false,
};

export default React.memo(BingoCard);
