import icon1 from 'assets/theme/svg/userPictures/user-picture-1.svg';
import icon2 from 'assets/theme/svg/userPictures/user-picture-2.svg';
import icon3 from 'assets/theme/svg/userPictures/user-picture-3.svg';
import icon4 from 'assets/theme/svg/userPictures/user-picture-4.svg';
import icon5 from 'assets/theme/svg/userPictures/user-picture-5.svg';
import icon6 from 'assets/theme/svg/userPictures/user-picture-6.svg';
import icon7 from 'assets/theme/svg/userPictures/user-picture-7.svg';
import icon8 from 'assets/theme/svg/userPictures/user-picture-8.svg';
import icon9 from 'assets/theme/svg/userPictures/user-picture-9.svg';
import icon10 from 'assets/theme/svg/userPictures/user-picture-10.svg';
import icon11 from 'assets/theme/svg/userPictures/user-picture-11.svg';
import icon12 from 'assets/theme/svg/userPictures/user-picture-12.svg';
import icon13 from 'assets/theme/svg/userPictures/user-picture-13.svg';
import icon14 from 'assets/theme/svg/userPictures/user-picture-14.svg';
import icon15 from 'assets/theme/svg/userPictures/user-picture-15.svg';
import icon16 from 'assets/theme/svg/userPictures/user-picture-16.svg';
import icon17 from 'assets/theme/svg/userPictures/user-picture-17.svg';
import icon18 from 'assets/theme/svg/userPictures/user-picture-18.svg';
import icon19 from 'assets/theme/svg/userPictures/user-picture-19.svg';
import icon20 from 'assets/theme/svg/userPictures/user-picture-20.svg';
import icon21 from 'assets/theme/svg/userPictures/user-picture-21.svg';
import icon22 from 'assets/theme/svg/userPictures/user-picture-22.svg';
import icon23 from 'assets/theme/svg/userPictures/user-picture-23.svg';
import icon24 from 'assets/theme/svg/userPictures/user-picture-24.svg';

const icons = [
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
  icon13,
  icon14,
  icon15,
  icon16,
  icon17,
  icon18,
  icon19,
  icon20,
  icon21,
  icon22,
  icon23,
  icon24,
];

export const getIconsList = () => icons;

export const getUserProfileImg = (index) => {
  if (index === -1) {
    return icons[icons.length - 1];
  }
  return icons[index];
};
