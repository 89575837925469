import React from 'react';
import useApiErrorStore from 'stores/api-error';
import text from 'polyglot/polyglot';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

const errorMessageIdSelector = (state) => state.errorMessageID;
const resetErrorMessageSelector = (state) => state.resetErrorMessage;

const ApiErrorModal = () => {
  const messageID = useApiErrorStore(errorMessageIdSelector);
  const resetMessage = useApiErrorStore(resetErrorMessageSelector);
  const onClose = () => {
    resetMessage();
  };

  return (
    <Modal
      title={
        messageID !== 9
          ? text.t(
              messageID >= 13 && messageID <= 17
                ? 'apiErrorModal.titleWhenBuying'
                : 'apiErrorModal.title'
            )
          : ''
      }
      content={
        <div>
          <p>{text.t(`apiErrorModal.content${messageID}`)}</p>
        </div>
      }
      buttons={[
        <Button key="cancel-button" onClick={onClose} isTertiary>
          {text.t('apiErrorModal.closeButtonText')}
        </Button>,
      ]}
    />
  );
};

export default ApiErrorModal;
