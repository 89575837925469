/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import shallow from 'zustand/shallow';
import { parseBingoCardsLayouts } from 'utils/helpers';
import text from 'polyglot/polyglot';

import api from 'utils/api';
import { bool } from 'prop-types';
import useSettingsStore from 'stores/settings';
import useGameStatsStore from 'stores/current-game';
import useCardRankingStore from 'stores/card-ranking';
import useApiErrorStore from 'stores/api-error';
import useLayoutStore from 'stores/layout';
import useGameStore, { GAME_REPLAY } from 'stores/game';
import useUserStore from 'stores/user';
import useNumbersDrawnStore from 'stores/numbers-drawn';
import useWinnerStatsStore from 'stores/winner-stats';

import BingoCardsLayout from 'components/BingoCardsLayout/BingoCardsLayout';
import Spinner from 'components/Spinner/Spinner';

import styles from './UserBingoCards.module.scss';

const symbolSelector = (state) => state.settings.symbol;
const symbolColorIdSelector = (state) => state.settings.color;
const autosortSelector = (state) => state.settings.autosort;
const automarkSelector = (state) => state.settings.automark;
const gameStateSelector = (state) => state.gameState;
const numbersDrawnSelector = (state) => [state.numbers];

const apiErrorSelector = (state) => state.setErrorMessage;

const bingoRoundIdSelector = (state) => [
  state.currentGame.bingoRoundId,
  state.setCurrentGame,
];
const bingocardIdSelector = (state) => state.user.bingocardId;
const cardRankingSelector = (state) => state.cardRanking;
const isSmallTicketsSelector = (state) => [
  state.isSmallTickets,
  state.setIsSmallTickets,
];
const isLongTicketsSelector = (state) => [
  state.isLongTickets,
  state.setIsLongTickets,
];

const addNewNumbersListSelector = (state) => state.addNewNumbersList;
const setScoreboardSelector = (state) => state.setScoreboardList;

const UserBingoCards = ({ isSideMenuOpen }) => {
  const currentSymbol = useSettingsStore(symbolSelector);
  const currentSymbolColorId = useSettingsStore(symbolColorIdSelector);
  const setApiErrorMessage = useApiErrorStore(apiErrorSelector);
  const gameState = useGameStore(gameStateSelector);
  const [numbersDrawn] = useNumbersDrawnStore(numbersDrawnSelector);

  const [bingoCards, setBingoCards] = useState([]);
  const cardRanking = useCardRankingStore(cardRankingSelector);
  const [bingoRoundId, setCurrentGame] = useGameStatsStore(
    bingoRoundIdSelector,
    shallow
  );
  const bingocardId = useUserStore(bingocardIdSelector);
  const autosort = useSettingsStore(autosortSelector);
  const automark = useSettingsStore(automarkSelector);
  const [isSmallTickets, setIsSmallTickets] = useLayoutStore(
    isSmallTicketsSelector,
    shallow
  );
  const [isLongTickets, setIsLongTickets] = useLayoutStore(
    isLongTicketsSelector,
    shallow
  );

  const addNewNumbersList = useNumbersDrawnStore(addNewNumbersListSelector);
  const setScoreboard = useWinnerStatsStore(setScoreboardSelector);
  const isReplay = gameState === GAME_REPLAY;
  const forceRerender = useRef(false);
  const mountedRef = useRef(true);
  const [isLoading, setIsLoading] = useState(true);

  const checkIfTicketIsSmall = (ticket) => {
    if (ticket.length === 5 && !isSmallTickets) {
      setIsSmallTickets(true);
    }
    if (ticket.length > 5 && isSmallTickets) {
      setIsSmallTickets(false);
    }
  };

  const checkIfTicketIsLong = (ticketRow) => {
    if (ticketRow.length === 9 && !isLongTickets) {
      setIsLongTickets(true);
    }
    if (ticketRow.length < 9 && isLongTickets) {
      setIsLongTickets(false);
    }
  };

  const collectReplayData = async () => {
    await api
      .get(`/api/Bingo/round/replay/${bingocardId}`)
      .then((res) => {
        setScoreboard({
          winners: res.winners || [],
          jackpotWinners: res.jackpotWinners || [],
        });
        const numberOfNotDrawnBalls =
          res.numberOfBalls -
          res.winners[res.winners.length - 1].afterNumberOfBalls;
        const drawnBalls = res.drawOrder;
        for (let i = 0; i < numberOfNotDrawnBalls; i++) {
          drawnBalls.pop();
        }
        addNewNumbersList(drawnBalls);
        setCurrentGame(res);
        const lotteryticket = {
          numbersMap: parseBingoCardsLayouts(res).numbersMap,
          id: bingocardId,
          cardNumber: '',
          currentSymbol,
          currentSymbolColorId,
          isDisabled: true,
          isDemo: false,
          bought: true,
        };
        setBingoCards([lotteryticket]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('error on retrieving tickets: ', err);
        setApiErrorMessage(6);
        setIsLoading(false);
      });
  };

  const collectBoughtBingoCards = async () => {
    await api
      .get(`/api/Bingo/cards/${bingoRoundId}`)
      .then((res) => {
        if (mountedRef.current) {
          res.map((item, index) => {
            parseBingoCardsLayouts(item);
            // item.canBePurchased = !item.bought;
            item.isPurchased = item.bought;
            item.id = item.checksum || item.cardId;
            item.cardNumber = String(index + 1);
            item.currentSymbol = currentSymbol;
            item.currentSymbolColorId = currentSymbolColorId;
            item.isDisabled = automark;
            item.isDemo = !item.bought;
            return item;
          });
          checkIfTicketIsSmall(res[0].numbersMap);
          checkIfTicketIsLong(res[0].numbersMap[0]);
          const purchasedCards = res.filter((item) => item.bought);
          if (purchasedCards.length) {
            setBingoCards(purchasedCards);
          } else {
            setBingoCards(res);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('error on retrieving tickets: ', err);
        setApiErrorMessage(6);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isReplay && !forceRerender.current && bingoCards.length) {
      forceRerender.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bingoCards]);

  useEffect(() => {
    if (mountedRef.current) {
      if (!isReplay) {
        setTimeout(() => {
          collectBoughtBingoCards();
        }, Math.random() * 3000);
      }
      if (isReplay) {
        collectReplayData();
      }
    }
    // Cleanup
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bingoCards.length) {
      setBingoCards((prevState) =>
        prevState.map((card) => ({
          ...card,
          currentSymbol,
          currentSymbolColorId,
          isDisabled: isReplay ? true : automark,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSymbol, currentSymbolColorId, automark, forceRerender.current]);

  useEffect(() => {
    if (!cardRanking.length || isReplay) return;
    if (bingoCards.length && !autosort) {
      const newOrder = [];
      for (let i = 1; i <= bingoCards.length; i++) {
        newOrder.push(
          bingoCards.find((oldCard) => oldCard.cardNumber === String(i))
        );
      }
      setBingoCards(newOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosort]);

  useEffect(() => {
    const lastBall = numbersDrawn[numbersDrawn.length - 1];
    cardRanking.forEach((card) => {
      if (card.winningNumbers.includes(lastBall)) {
        const foundCard = bingoCards.find(
          (oldCard) => oldCard.id === card.bingoCardId
        );
        if (foundCard) {
          foundCard.footerText = text.t('bingoCard.bingo');
          bingoCards.forEach((c) => {
            c.winningNumbers = [];
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbersDrawn]);

  useEffect(() => {
    //  Fallback in case bingo cards have not been collected
    if (!bingoCards.length && !isLoading) {
      collectBoughtBingoCards();
    }
    // Check if we the cardranking cards match ours, otherwise don't update
    // Bugfix due to server sending out cards from all active rooms
    if (bingoCards.length && cardRanking.length) {
      const foundCard = bingoCards.find(
        (card) => card.id === cardRanking[0].bingoCardId
      );
      if (!foundCard) return;
    }
    if (cardRanking.length && bingoCards.length && autosort) {
      const newOrder = [];
      cardRanking.forEach((card) => {
        const foundCard = bingoCards.find(
          (oldCard) => oldCard.id === card.bingoCardId
        );
        if (foundCard) {
          foundCard.footerText = String(card.numberOfNumbersToWin);
          foundCard.winningNumbers = card.winningNumbers || null;
          newOrder.push(foundCard);
        }
      });
      setBingoCards(newOrder);
    } else if (cardRanking.length && bingoCards.length && !autosort) {
      setBingoCards((prevState) =>
        prevState.map((card) => ({
          ...card,
          winningNumbers: cardRanking.find((c) => c.bingoCardId === card.id)
            .winningNumbers,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRanking]);

  if (isLoading) return <Spinner />;

  return (
    <div className={styles.wrapper}>
      <BingoCardsLayout items={bingoCards} isSideMenuOpen={isSideMenuOpen} />
    </div>
  );
};

UserBingoCards.propTypes = {
  isSideMenuOpen: bool,
};

UserBingoCards.defaultProps = {
  isSideMenuOpen: false,
};

export default UserBingoCards;
