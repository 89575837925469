import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import api from 'utils/api';

import shallow from 'zustand/shallow';
import useMenuStore from 'stores/menu';
import useGameStore, { PURCHASE_VIEW, ACTIVE_GAME } from 'stores/game';
import useNumbersMarkedStore from 'stores/numbers-marked';
import useCurrentGameStore from 'stores/current-game';
import usePurchaseStore from 'stores/purchase';
import useUpcomingGameStore from 'stores/upcoming-game';
import { useChatStore } from 'stores/chat';
import useBreakpoint, { MOBILE, TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import useWinnerStatsStore from 'stores/winner-stats';
import useLeaderboardStore from 'stores/leaderboard';

import GameLayout from 'components/GameInfoPanel/GameLayout/GameLayout';
import PurchaseLayout from 'components/GameInfoPanel/PurchaseLayout/PurchaseLayout';
import PurchaseModal from 'components/PurchaseModal/PurchaseModal';

import ArrowHead from 'assets/svg/arrowhead.svg';

import styles from './GameInfoPanel.module.scss';

const chatSelector = (state) => state.isChatExpanded;
const menuOpenSelector = (state) => state.isMenuOpen;
const gameStateSelector = (state) => state.gameState;
const isMenuExpandedSelector = (state) => [
  state.isMenuExpanded,
  state.setIsMenuExpanded,
];
const scoreboardSelector = (state) => state.scoreboard;
const leaderboardSelector = (state) => state.resetLeaderboard;
const upcomingGameId = (state) => state.nextGame.bingoRoundId;
const currentGameIdSelector = (state) => state.currentGame.bingoRoundId;
const ticketsNextGameSelector = (state) => [
  state.numberOfTicketsNextGame,
  state.updateTicketsNextGame,
];
const ticketsCurrentGameSelector = (state) => state.updateTicketsCurrentGame;
const resetMarkedNumbersSelector = (state) => state.resetMarkedNumbers;

const GameInfoPanel = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useMenuStore(
    isMenuExpandedSelector,
    shallow
  );
  const gameState = useGameStore(gameStateSelector);
  const isChatExpanded = useChatStore(chatSelector);
  const isMenuOpen = useMenuStore(menuOpenSelector);
  const [isPurchaseDuringGame, setIsPurchaseDuringGame] = useState(false);
  const [hasPurchaseModal, setHasPurchaseModal] = useState(false);
  const scoreboard = useWinnerStatsStore(scoreboardSelector);
  const resetLeaderboard = useLeaderboardStore(leaderboardSelector);
  const nextBingoRoundId = useUpcomingGameStore(upcomingGameId);
  const currentBingoRoundId = useCurrentGameStore(currentGameIdSelector);
  const updateTicketsCurrentGame = usePurchaseStore(ticketsCurrentGameSelector);
  const [numberOfTicketsNextGame, updateTicketsNextGame] = usePurchaseStore(
    ticketsNextGameSelector,
    shallow
  );
  const resetMarkedNumbers = useNumbersMarkedStore(resetMarkedNumbersSelector);

  const isPurchase = gameState === PURCHASE_VIEW || isPurchaseDuringGame;
  const isOpenSidemenu = isChatExpanded || isMenuOpen;
  const isMobile = useBreakpoint() === MOBILE;
  const isLandscape = useBreakpoint() === TABLET_LANDSCAPE;

  useEffect(() => {
    if (scoreboard && isPurchaseDuringGame) {
      setIsPurchaseDuringGame(false);
    }
    if (gameState === PURCHASE_VIEW && isPurchaseDuringGame) {
      setIsPurchaseDuringGame(false);
    }
    if (gameState === PURCHASE_VIEW) {
      resetLeaderboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreboard, gameState]);

  useEffect(() => {
    if (gameState === PURCHASE_VIEW) {
      updateTicketsCurrentGame(numberOfTicketsNextGame);
      updateTicketsNextGame(0);
      resetMarkedNumbers();
    }
    if (gameState === ACTIVE_GAME) {
      updateTicketsCurrentGame(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  const loadBoughtTickets = async (activeGame) => {
    await api
      .get(
        `/api/Bingo/cards/${
          activeGame ? nextBingoRoundId : currentBingoRoundId
        }`
      )
      .then((res) => {
        let boughtCards = 0;
        res.forEach((ticket) => {
          if (ticket.bought) {
            boughtCards += 1;
          }
        });
        if (activeGame) {
          updateTicketsNextGame(boughtCards);
        } else {
          updateTicketsCurrentGame(boughtCards);
        }
      })
      .catch((err) => {
        console.log('error on retrieving tickets: ', err);
      });
  };
  const onClickCloseModal = () => {
    setHasPurchaseModal(false);
    setIsPurchaseDuringGame(false);
  };

  useEffect(() => {
    if (gameState === ACTIVE_GAME && nextBingoRoundId) {
      setTimeout(() => {
        loadBoughtTickets(true);
      }, Math.random() * 3000);
    } else if (currentBingoRoundId) {
      setTimeout(() => {
        loadBoughtTickets(false);
      }, Math.random() * 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isExpanded]: isMenuExpanded || isPurchase,
        [styles.isLandscape]: isLandscape,
      })}
    >
      <button
        type="button"
        className={clsx(styles.expandedButton, {
          [styles.isExpanded]: isMenuExpanded,
          [styles.isVisible]: !isPurchase,
        })}
        onClick={() => setIsMenuExpanded(!isMenuExpanded)}
      >
        <ArrowHead />
      </button>
      {isPurchase ? (
        <>
          <PurchaseLayout
            isPurchaseDuringGame={isPurchaseDuringGame}
            setIsPurchaseDuringGame={setIsPurchaseDuringGame}
            setHasPurchaseModal={setHasPurchaseModal}
          />
          <div
            className={clsx(styles.overlay, {
              [styles.isVisible]: isMobile && isOpenSidemenu,
            })}
          />
        </>
      ) : (
        <GameLayout
          setIsPurchaseDuringGame={setIsPurchaseDuringGame}
          isSideMenuOpen={isMobile && isOpenSidemenu}
        />
      )}
      {hasPurchaseModal && (
        <PurchaseModal
          onClose={onClickCloseModal}
          isPrebuy={isPurchaseDuringGame}
        />
      )}
    </div>
  );
};

export default GameInfoPanel;
