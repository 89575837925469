import React, { useEffect } from 'react';
import { func } from 'prop-types';
import text from 'polyglot/polyglot';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import useMenuStore from 'stores/menu';
import useTutorialStore from 'stores/tutorial';

import UserProfileContainer from 'components/TopbarPanel/UserProfileContainer/UserProfileContainer';

import Dialogue from '../Dialogue/Dialogue';
import styles from './Step1Deposit.module.scss';

const setIsMenuOpenSelector = (state) => state.setIsMenuOpen;
const userProfilePositionSelector = (state) => state.userProfilePosition;

const Step1Deposit = ({ nextStep, closeTutorial }) => {
  const breakpoint = useBreakpoint();
  const setIsMenuOpen = useMenuStore(setIsMenuOpenSelector);
  const userProfilePosition = useTutorialStore(userProfilePositionSelector);
  const profileWidth = breakpoint === DESKTOP ? 280 : 256;

  useEffect(() => {
    if (breakpoint) {
      if (breakpoint === DESKTOP) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoint]);

  return (
    <div
      className={styles.wrapper}
      style={{
        top: userProfilePosition.y || 0,
        left: userProfilePosition.x || 0,
        height: userProfilePosition.height || 54,
        width: userProfilePosition.width || profileWidth,
      }}
    >
      <UserProfileContainer isTutorialView />
      <Dialogue
        className={styles.dialogue}
        text={text.t('tutorial.step1Text')}
        nextStep={nextStep}
        closeTutorial={closeTutorial}
        hasCloseButtonLeft={breakpoint !== DESKTOP}
        hasRightPositionedArrow={breakpoint !== DESKTOP}
        delayTransition={breakpoint !== DESKTOP}
      />
    </div>
  );
};

Step1Deposit.propTypes = {
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
};

export default Step1Deposit;
