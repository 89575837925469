import React from 'react';
import { string, bool } from 'prop-types';
import clsx from 'clsx';

import useBreakpoint, { MOBILE, TABLET_LANDSCAPE } from 'hooks/useBreakpoint';

import usePrizePlanStore from 'stores/prizeplan-modal';

import Button from 'components/Button/Button';

import infoIcon from 'assets/theme/svg/info.svg';

import styles from './LargerBanner.module.scss';

const LargerBanner = ({
  hideInfoButton,
  background,
  logoSmall,
  logoBig,
  text,
  hours,
  minutes,
  seconds,
}) => {
  const breakpoint = useBreakpoint();
  const smallMobile = breakpoint === MOBILE && window.innerWidth < 374;
  const setModalVisibility = usePrizePlanStore(
    (state) => state.setModalVisibility
  );

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className={clsx(styles.imgWrapper, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        <img
          src={smallMobile ? logoSmall : logoBig}
          alt=""
          className={styles.logo}
        />
        <Button
          isIcon
          onClick={setModalVisibility}
          isDisabled={hideInfoButton}
          className={clsx(styles.infoButton, {
            [styles.isHidden]: hideInfoButton,
          })}
        >
          <img src={infoIcon} alt="" className={styles.info} />
        </Button>
      </div>
      <div className={styles.textWrapper}>
        <span className={styles.text}>{text}</span>
        <span className={styles.time}>
          {hours && minutes && seconds && `${hours}:${minutes}:${seconds}`}
        </span>
      </div>
    </div>
  );
};

LargerBanner.propTypes = {
  hideInfoButton: bool,
  background: string.isRequired,
  logoSmall: string.isRequired,
  logoBig: string.isRequired,
  text: string.isRequired,
  hours: string.isRequired,
  minutes: string.isRequired,
  seconds: string.isRequired,
};

LargerBanner.defaultProps = {
  hideInfoButton: false,
};

export default LargerBanner;
