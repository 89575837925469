import { getCssVariable } from './style-variables';

const getBingoBallColor = (bingoballNumber, returnColumn = false) => {
  if (bingoballNumber <= 15) {
    const hexcolor = getCssVariable('--column-1-bingo-color-75-total');
    if (returnColumn) {
      return 1;
    }
    return hexcolor;
  }
  if (bingoballNumber > 15 && bingoballNumber <= 30) {
    const hexcolor = getCssVariable('--column-2-bingo-color-75-total');
    if (returnColumn) {
      return 2;
    }
    return hexcolor;
  }
  if (bingoballNumber > 30 && bingoballNumber <= 45) {
    const hexcolor = getCssVariable('--column-3-bingo-color-75-total');
    if (returnColumn) {
      return 3;
    }
    return hexcolor;
  }
  if (bingoballNumber > 45 && bingoballNumber <= 60) {
    const hexcolor = getCssVariable('--column-4-bingo-color-75-total');
    if (returnColumn) {
      return 4;
    }
    return hexcolor;
  }
  const hexcolor = getCssVariable('--column-5-bingo-color-75-total');
  if (returnColumn) {
    return 5;
  }
  return hexcolor;
};

export default getBingoBallColor;
