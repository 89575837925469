import React, { forwardRef } from 'react';
import { string, node } from 'prop-types';
import clsx from 'clsx';

import styles from './Scrollbars.module.scss';

const Scrollbars = forwardRef(({ className, children }, ref) => (
  <div ref={ref} className={clsx(className, styles.wrapper)}>
    {children}
  </div>
));

Scrollbars.propTypes = {
  className: string,
  children: node,
};

Scrollbars.defaultProps = {
  className: null,
  children: null,
};

export default Scrollbars;
