import React from 'react';

import text from 'polyglot/polyglot';
import shallow from 'zustand/shallow';
import api from 'utils/api';
import useSettingsStore from 'stores/settings';
import useApiErrorStore from 'stores/api-error';

import ToggleButton from 'components/SettingsWindow/SettingsToggleButton/SettingsToggleButton';
import MarkingSymbols from 'components/SettingsWindow/MarkingSymbols/MarkingSymbols';
import ColorBoxes from 'components/SettingsWindow/ColorBoxes/ColorBoxes';
import ProfileIcons from 'components/SettingsWindow/ProfileIcons/ProfileIcons';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import styles from './SettingsWindow.module.scss';

const settingsSelector = (state) => [state.settings, state.updateSettings];
const apiErrorSelector = (state) => state.setErrorMessage;

const SettingsWindow = () => {
  const [settings, updateSettings] = useSettingsStore(
    settingsSelector,
    shallow
  );
  const setApiErrorMessage = useApiErrorStore(apiErrorSelector);

  const newSettings = async (category, newValue) => {
    if (category === 'autosort' && newValue === true && !settings.automark) {
      return;
    }
    if (category === 'automark' && newValue === false) {
      updateSettings({ [category]: newValue, autosort: false });
    } else {
      updateSettings({ [category]: newValue });
    }
    const data = {
      autoSort: settings.autosort,
      autoMark: settings.automark,
      symbol: settings.symbol,
      color: settings.color,
      announcer: settings.announcer,
      effects: settings.effects,
      music: settings.music,
      userAvatar: settings.userAvatar,
      muted: settings.muted,
    };
    if (category === 'autosort') {
      data.autoSort = newValue;
    } else if (category === 'automark') {
      data.autoMark = newValue;
      if (!newValue) {
        data.autoSort = false;
      }
    } else {
      data[category] = newValue;
    }

    api
      .put('/api/Bingo/clientsetting', data)
      .then(() => {})
      .catch((err) => {
        console.log('err: ', err);
        setApiErrorMessage(4);
      });
  };

  const sendDataLayer = (value) => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'sendEvent',
      eventCategory: 'Bingoklient',
      eventAction: 'Installning',
      eventLabel: value,
    });
  };

  return (
    <div className={styles.wrapper}>
      <Scrollbars>
        <span className={styles.header}>{text.t('settings.heading')}</span>
        <ToggleButton
          label={text.t('settings.autosort')}
          onClick={() => newSettings('autosort', !settings.autosort)}
          isActive={settings.autosort}
        />
        <p className={styles.explanation}>
          {text.t('settings.autosortExplanation')}
        </p>
        <ToggleButton
          label={text.t('settings.automark')}
          onClick={() => {
            sendDataLayer(
              !settings.automark ? 'Autoduttning on' : 'Autoduttning off'
            );
            newSettings('automark', !settings.automark);
          }}
          isActive={settings.automark}
        />
        <p className={styles.explanation}>
          {text.t('settings.automarkExplanation')}
        </p>
        <span className={styles.subheader}>{text.t('settings.symbols')}</span>
        <MarkingSymbols
          currentSymbol={settings.symbol}
          onClick={(category, newValue) => {
            sendDataLayer('Byte duttsymbol');
            newSettings(category, newValue);
          }}
        />
        <span className={styles.subheader}>{text.t('settings.colors')}</span>
        <ColorBoxes
          selectedColor={settings.color}
          onSelectNewColor={(category, newValue) => {
            sendDataLayer('Byte duttfärg');
            newSettings(category, newValue);
          }}
        />
        <span className={styles.subheader}>{text.t('settings.sound')}</span>
        <ToggleButton
          label={text.t('settings.announcer')}
          onClick={() => {
            sendDataLayer(!settings.announcer ? 'Utropare on' : 'Utropare off');
            newSettings('announcer', !settings.announcer);
          }}
          isActive={settings.announcer}
        />
        {/* <ToggleButton
          label={text.t('settings.effects')}
          onClick={() => newSettings('effects', !settings.effects)}
          isActive={settings.effects}
        />
        <ToggleButton
          label={text.t('settings.music')}
          onClick={() => newSettings('music', !settings.music)}
          isActive={settings.music}
        /> */}
        <span className={styles.subheader}>
          {text.t('settings.profileimage')}
        </span>
        <ProfileIcons
          selectedIcon={settings.userAvatar}
          onSelectNewIcon={(category, newValue) => {
            sendDataLayer('Byte profilbild');
            newSettings(category, newValue);
          }}
        />
      </Scrollbars>
    </div>
  );
};

export default SettingsWindow;
