import React from 'react';
import clsx from 'clsx';
import shallow from 'zustand/shallow';

import usePurchaseStore from 'stores/purchase';
import useUpcomingGameStore from 'stores/upcoming-game';
import useGameStatsStore from 'stores/current-game';
import text from 'polyglot/polyglot';
import { bool } from 'prop-types';

import styles from './BuyLotteryTicketButtons.module.scss';

const selectedCardIdsSelector = (state) => [
  state.selectedCardIds,
  state.updateSelectedCardIds,
];
const bingoCardsListSelector = (state) => [state.bingoCardsList];
const upcomingGameSelector = (state) => state.nextGame;
const currentGameSelector = (state) => state.currentGame;
const prebuyTicketAmountSelector = (state) => [
  state.prebuyTicketAmount,
  state.updatePrebuyTicketAmount,
];
const ticketsNextGameSelector = (state) => state.numberOfTicketsNextGame;
const ticketsCurrentGameSelector = (state) => state.numberOfTicketsCurrentGame;

const BuyLotteryTicketButtons = ({ isPrebuy }) => {
  const [selectedTicketIds, setSelectedTicketIds] = usePurchaseStore(
    selectedCardIdsSelector,
    shallow
  );
  const [bingoCardsList] = usePurchaseStore(bingoCardsListSelector);
  const availableCards = bingoCardsList.filter((card) => !card.isPurchased);
  const unselectedCards = availableCards.filter(
    (card) => !selectedTicketIds.includes(card.id)
  );
  const [prebuyTicketAmount, setPrebuyTicketAmount] = usePurchaseStore(
    prebuyTicketAmountSelector,
    shallow
  );
  const nextGame = useUpcomingGameStore(upcomingGameSelector);
  const currentGame = useGameStatsStore(currentGameSelector);

  const numberOfTicketsCurrentGame = usePurchaseStore(
    ticketsCurrentGameSelector
  );
  const numberOfTicketsNextGame = usePurchaseStore(ticketsNextGameSelector);

  const removeOneTicket = () => {
    if (isPrebuy && prebuyTicketAmount > 0) {
      setPrebuyTicketAmount(prebuyTicketAmount - 1);
    }
    if (!isPrebuy && selectedTicketIds.length) {
      setSelectedTicketIds(selectedTicketIds[selectedTicketIds.length - 1]);
    }
  };

  const addOneTicket = () => {
    if (
      isPrebuy &&
      prebuyTicketAmount + numberOfTicketsNextGame < nextGame.maxNumberOfCards
    ) {
      setPrebuyTicketAmount(prebuyTicketAmount + 1);
    }
    if (
      !isPrebuy &&
      unselectedCards.length &&
      numberOfTicketsCurrentGame + selectedTicketIds.length <
        currentGame.maxNumberOfCards
    ) {
      setSelectedTicketIds(unselectedCards[0].id);
    }
  };

  const updateNumberOfTickets = async (numberOfCards) => {
    if (numberOfCards === selectedTicketIds.length) {
      return;
    }
    if (numberOfCards > selectedTicketIds.length) {
      const difference = numberOfCards - selectedTicketIds.length;
      const idsToSelect = [];
      unselectedCards.map((card, index) => {
        if (index < difference) {
          idsToSelect.push(card.id);
        }
        return null;
      });
      idsToSelect.map((id) => setSelectedTicketIds(id));
    } else {
      const difference = selectedTicketIds.length - numberOfCards;
      selectedTicketIds.reverse().map((card, index) => {
        if (index < difference) {
          setSelectedTicketIds(card);
        }
        return null;
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        {text.t('buyLotteryTicketButtons.heading')}
      </span>
      <div className={styles.firstSection}>
        <button
          type="button"
          className={clsx(styles.payButton, styles.minusButton)}
          disabled={isPrebuy ? !prebuyTicketAmount : !selectedTicketIds.length}
          onClick={removeOneTicket}
        >
          {text.t('buyLotteryTicketButtons.minusButton')}
        </button>
        <div className={clsx(styles.payButton, styles.displayWrapper)}>
          {`${isPrebuy ? prebuyTicketAmount : selectedTicketIds.length} st`}
        </div>
        <button
          type="button"
          className={clsx(styles.payButton, styles.plusButton)}
          disabled={
            isPrebuy
              ? prebuyTicketAmount >= nextGame.maxNumberOfCards
              : !unselectedCards.length
          }
          onClick={addOneTicket}
        >
          {text.t('buyLotteryTicketButtons.plusButton')}
        </button>
      </div>
      <div className={styles.secondSection}>
        <button
          type="button"
          className={styles.payButton}
          disabled={
            isPrebuy
              ? numberOfTicketsNextGame + 2 > nextGame.maxNumberOfCards
              : numberOfTicketsCurrentGame + 2 > currentGame.maxNumberOfCards
          }
          onClick={() => {
            if (isPrebuy) {
              setPrebuyTicketAmount(2);
            } else {
              updateNumberOfTickets(2);
            }
          }}
        >
          {text.t('buyLotteryTicketButtons.predefinedButtons', { amount: 2 })}
        </button>
        <button
          type="button"
          className={styles.payButton}
          disabled={
            isPrebuy
              ? numberOfTicketsNextGame + 4 > nextGame.maxNumberOfCards
              : numberOfTicketsCurrentGame + 4 > currentGame.maxNumberOfCards
          }
          onClick={() => {
            if (isPrebuy) {
              setPrebuyTicketAmount(4);
            } else {
              updateNumberOfTickets(4);
            }
          }}
        >
          {text.t('buyLotteryTicketButtons.predefinedButtons', { amount: 4 })}
        </button>
        <button
          type="button"
          className={styles.payButton}
          disabled={
            isPrebuy
              ? prebuyTicketAmount + numberOfTicketsNextGame >=
                nextGame.maxNumberOfCards
              : selectedTicketIds.length + numberOfTicketsCurrentGame >=
                currentGame.maxNumberOfCards
          }
          onClick={() => {
            if (isPrebuy) {
              setPrebuyTicketAmount(
                nextGame.maxNumberOfCards - numberOfTicketsNextGame
              );
            } else {
              if (
                currentGame.maxNumberOfCards - numberOfTicketsCurrentGame >
                availableCards.length
              )
                return;
              updateNumberOfTickets(
                currentGame.maxNumberOfCards - numberOfTicketsCurrentGame
              );
            }
          }}
        >
          {text.t('buyLotteryTicketButtons.maxButton')}
        </button>
      </div>
    </div>
  );
};

BuyLotteryTicketButtons.propTypes = {
  isPrebuy: bool.isRequired,
};

export default BuyLotteryTicketButtons;
