import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { useUpdateEffect } from 'react-use';

import text from 'polyglot/polyglot';

import { useChatStore } from 'stores/chat';

import Avatar from 'components/Chat/Avatar/Avatar';

import CrossIcon from 'assets/svg/cross.svg';

import styles from './PrivateChatHeader.module.scss';

const chatroomsSelector = (state) => state.chatrooms;

const PrivateChatHeader = ({ currentRoomId, onClickReturn }) => {
  const chatrooms = useChatStore(chatroomsSelector);
  const currentRoomIndex = chatrooms.findIndex(
    (room) => room.roomId === currentRoomId
  );
  const [recipientName, setRecipientName] = useState(
    chatrooms[currentRoomIndex].chatName
  );
  const [recipientAvatar, setRecipientAvatar] = useState(
    chatrooms[currentRoomIndex].icon
  );

  // Update users from room.
  useUpdateEffect(() => {
    const newRoomIndex = chatrooms.findIndex(
      (room) => room.roomId === currentRoomId
    );
    setRecipientName(chatrooms[newRoomIndex].chatName);
    setRecipientAvatar(chatrooms[newRoomIndex].icon);
  }, [currentRoomId]);

  return (
    <>
      <div key={recipientAvatar} className={styles.wrapper}>
        <div className={styles.user}>
          {recipientName && (
            <>
              <Avatar avatar={recipientAvatar} className={styles.userAvatar} />

              <div className={styles.userInfo}>
                <span className={styles.userLabel}>
                  {text.t('chat.private')}
                </span>
                <br />
                <span className={styles.userName}>{recipientName}</span>
              </div>
            </>
          )}
        </div>

        <button
          type="button"
          className={styles.button}
          onClick={() => onClickReturn(currentRoomId)}
        >
          {text.t('chat.closeButton')}

          <span className={styles.icon}>
            <CrossIcon />
          </span>
        </button>
      </div>
    </>
  );
};

PrivateChatHeader.propTypes = {
  currentRoomId: string,
  onClickReturn: func,
};

PrivateChatHeader.defaultProps = {
  currentRoomId: null,
  onClickReturn: () => {},
};

export default PrivateChatHeader;
