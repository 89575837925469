export const emojiList = [
  '🙂',
  '😊',
  '😀',
  '😄',
  '😁',
  '😯',
  '😍',
  '😲',
  '😎',
  '😒',
  '😥',
  '😴',
  '🙁',
  '😂',
  '😢',
  '🤗',
  '🙃',
  '🤓',
  '😐',
  '🤑',
  '😇',
  '😏',
  '🤔',
  '😜',
  '😝',
  '🙈',
  '🙉',
  '🙊',
  '👇',
  '👉',
  '👆',
  '👈',
  '🤙',
  '👏',
  '🤞',
  '💪',
  '🖐',
  '☝️',
  '👌',
  '👊',
  '👐',
  '🤚',
  '✊',
  '✋',
  '🤘',
  '👎',
  '👍',
  '✌️',
  '🖖',
  '👋',
  '✍️',
  '💅',
  '🤛',
  '🤝',
  '🤜',
  '👂',
];

export const emojiCodes = [
  '1f642',
  '1f60a',
  '1f600',
  '1f604',
  '1f601',
  '1f62f',
  '1f60d',
  '1f632',
  '1f60e',
  '1f612',
  '1f625',
  '1f634',
  '1f641',
  '1f602',
  '1f622',
  '1f917',
  '1f643',
  '1f913',
  '1f610',
  '1f911',
  '1f607',
  '1f60f',
  '1f914',
  '1f61c',
  '1f61d',
  '1f648',
  '1f649',
  '1f64a',
  '1f447',
  '1f449',
  '1f446',
  '1f448',
  '1f919',
  '1f44f',
  '1f91e',
  '1f4aa',
  '1f590',
  '261d fe0f',
  '1f44c',
  '1f44a',
  '1f450',
  '1f91a',
  '270a',
  '270b',
  '1f918',
  '1f44e',
  '1f44d',
  '270c fe0f',
  '1f596',
  '1f44b',
  '270d fe0f',
  '1f485',
  '1f91b',
  '1f91d',
  '1f91c',
  '1f442',
];

export default emojiCodes;
