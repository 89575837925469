import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { func } from 'prop-types';
import text from 'polyglot/polyglot';
import clsx from 'clsx';

import GameInfoBar from 'components/BingoPanel/GameInfoBar/GameInfoBar';
import useBreakpoint, { MOBILE, TABLET_PORTRAIT } from 'hooks/useBreakpoint';
import tutorialStore from 'stores/tutorial';
import { useChatStore } from 'stores/chat';
import useMenuStore from 'stores/menu';

import styles from './Step4CurrentGame.module.scss';
import Dialogue from '../Dialogue/Dialogue';

const tutorialDialoguePositionSelector = (state) => [
  state.tutorialDialoguePosition,
  state.setTutorialDialoguePosition,
];
const chatSelector = (state) => state.isChatExpanded;
const menuSelector = (state) => [state.isMenuOpen, state.setIsMenuOpen];

const Step4CurrentGame = ({ nextStep, closeTutorial }) => {
  const [tutorialDialoguePosition] = tutorialStore(
    tutorialDialoguePositionSelector,
    shallow
  );
  const [isMenuOpen, setIsMenuOpen] = useMenuStore(menuSelector, shallow);
  const isChatExpanded = useChatStore(chatSelector);

  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === MOBILE;
  const isTablet = breakpoint === TABLET_PORTRAIT;

  let marginLeftDesktop;
  let marginLeftTablet;
  let marginLeftMobile;
  if (tutorialDialoguePosition.gameInfoIcon) {
    marginLeftDesktop = tutorialDialoguePosition.gameInfoIcon.left - 122;
    marginLeftTablet = tutorialDialoguePosition.gameInfoIcon.left - 41;
    marginLeftMobile = tutorialDialoguePosition.gameInfoIcon.left - 275;
  }
  const customStylingDesktop = { marginLeft: marginLeftDesktop || '0' };
  const customStylingTablet = { marginLeft: marginLeftTablet || '0' };
  const customStylingMobile = { marginLeft: marginLeftMobile || '0' };

  let gameInfoBarPosition = 0;
  if (tutorialDialoguePosition.gameInfoBar) {
    gameInfoBarPosition = tutorialDialoguePosition.gameInfoBar.top;
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        top: gameInfoBarPosition,
      }}
    >
      <div
        className={clsx(styles.gameInfoBarWrapper, {
          [styles.chatExpanded]: isChatExpanded,
        })}
      >
        <GameInfoBar className={styles.gameInfoBar} isPartOfTutorial />
      </div>
      {isMobile ? (
        <Dialogue
          className={styles.dialogue}
          text={text.t('tutorial.step4Text')}
          nextStep={nextStep}
          closeTutorial={closeTutorial}
          hasRightPositionedArrow
          hasCloseButtonLeft
          customStyling={customStylingMobile}
        />
      ) : (
        <Dialogue
          className={styles.dialogue}
          text={text.t('tutorial.step4Text')}
          nextStep={nextStep}
          closeTutorial={closeTutorial}
          hasLeftPositionedArrow={!!isTablet}
          customStyling={isTablet ? customStylingTablet : customStylingDesktop}
        />
      )}
    </div>
  );
};

Step4CurrentGame.propTypes = {
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
};

export default Step4CurrentGame;
