import create from 'zustand';

const useGameErrorStore = create((set) => ({
  isGameErrorVisible: false,
  bingoName: '',
  setIsGameErrorVisible: (value) => set(() => ({ isGameErrorVisible: value })),
  setGameErrorBingoname: (value) => set(() => ({ bingoName: value })),
}));

export default useGameErrorStore;
