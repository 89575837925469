import create from 'zustand';

const useGameStatsStore = create((set) => ({
  currentGame: {
    bingoRoundId: null,
    bingoGameId: null,
    backgroundBig: null,
    backgroundSmall: null,
    logoBig: null,
    logoSmall: null,
    name: null,
    jackpots: [],
    pot: null,
    numberOfPlayers: null,
    numbersLeft: null,
    maxNumberOfCards: null,
    numberOfCards: null,
    prizes: [],
    cardPrice: null,
    outcallVoice: null,
    rules: null,
    secondsBetweenNumbersDrawn: null,
    secondsToDisplayWinners: null,
    startsInSeconds: null,
  },
  setCurrentGame: (newValues) => set(() => ({ currentGame: newValues })),
  resetPrizes: () =>
    set((state) => ({ currentGame: { ...state.currentGame, prizes: [] } })),
}));

export default useGameStatsStore;
