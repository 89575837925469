import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import text from 'polyglot/polyglot';
import shallow from 'zustand/shallow';
import useTutorialStore from 'stores/tutorial';
import useMenuStore from 'stores/menu';

import Button from 'components/Button/Button';
import Step1Deposit from './Step1Deposit/Step1Deposit';
import Step2Settings from './Step2Settings/Step2Settings';
import Step3Sound from './Step3Sound/Step3Sound';
import Step4CurrentGame from './Step4CurrentGame/Step4CurrentGame';
import Step5ViewChat from './Step5ViewChat/Step5ViewChat';
import styles from './Tutorial.module.scss';

const tutorialStepSelector = (state) => [
  state.tutorialStep,
  state.setTutorialStep,
];
const setTutorialIsActiveSelector = (state) => state.setTutorialIsActive;
const menuOpenSelector = (state) => [state.isMenuOpen, state.setIsMenuOpen];

const Tutorial = () => {
  const [tutorialStep, setTutorialStep] = useTutorialStore(
    tutorialStepSelector,
    shallow
  );
  const [isSideMenuActive, setSideMenuActive] = useMenuStore(
    menuOpenSelector,
    shallow
  );
  const setTutorialIsActive = useTutorialStore(setTutorialIsActiveSelector);
  const modalWrapperRef = useRef(
    document.getElementById('modal-root-tutorial')
  );

  const nextStep = () => {
    setTutorialStep(tutorialStep + 1);
  };

  const closeTutorial = () => {
    setTutorialIsActive(false);
    setTutorialStep(1);
    if (isSideMenuActive) {
      setSideMenuActive(false);
    }
  };

  useLayoutEffect(() => {
    // If the doom hasn't loaded when the modal is shown,
    // we fetch it again with useLayoutEffect
    modalWrapperRef.current = document.getElementById('modal-root-tutorial');
  }, []);

  if (!modalWrapperRef.current) return null;

  return createPortal(
    <div className={styles.wrapper}>
      {tutorialStep === 0 && (
        <div className={styles.modal}>
          <h3>{text.t('tutorial.greetingHeading')}</h3>
          <p>{text.t('tutorial.greetingParagraph')}</p>
          <div className={styles.buttonWrapper}>
            <Button onClick={nextStep}>{text.t('tutorial.yesButton')}</Button>
            <Button onClick={closeTutorial} isSecondary>
              {text.t('tutorial.noButton')}
            </Button>
          </div>
        </div>
      )}
      {tutorialStep === 1 && (
        <Step1Deposit nextStep={nextStep} closeTutorial={closeTutorial} />
      )}
      {tutorialStep === 2 && (
        <Step2Settings nextStep={nextStep} closeTutorial={closeTutorial} />
      )}
      {tutorialStep === 3 && (
        <Step3Sound nextStep={nextStep} closeTutorial={closeTutorial} />
      )}
      {tutorialStep === 4 && (
        <Step4CurrentGame nextStep={nextStep} closeTutorial={closeTutorial} />
      )}
      {tutorialStep === 5 && (
        <Step5ViewChat nextStep={nextStep} closeTutorial={closeTutorial} />
      )}
    </div>,
    modalWrapperRef.current
  );
};

Tutorial.propTypes = {};

Tutorial.defaultProps = {};

export default Tutorial;
