import React, { useRef, useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { bool } from 'prop-types';
import shallow from 'zustand/shallow';

import useLayoutStore from 'stores/layout';
import tutorialStore from 'stores/tutorial';

import LayoutShape1 from 'assets/svg/layout-shape-1.svg';
import LayoutShape2 from 'assets/svg/layout-shape-2.svg';
import LayoutShape3 from 'assets/svg/layout-shape-3.svg';
import LayoutShape4 from 'assets/svg/layout-shape-4.svg';
import LayoutShape5 from 'assets/svg/layout-shape-5.svg';
import LayoutShape6 from 'assets/svg/layout-shape-6.svg';

import styles from './LayoutButtons.module.scss';

const currentLayoutSelector = (state) => [state.layoutId, state.setLayoutId];
const isSmallTicketsSelector = (state) => state.isSmallTickets;
const isLongTicketsSelector = (state) => state.isLongTickets;
const layoutButtonsPositionSelector = (state) => state.setLayoutButtonsPosition;

const LayoutButtons = ({ isPartOfTutorial }) => {
  const [currentLayoutId, setLayoutId] = useLayoutStore(
    currentLayoutSelector,
    shallow
  );
  const setLayoutButtonsPosition = tutorialStore(layoutButtonsPositionSelector);
  const isSmallTickets = useLayoutStore(isSmallTicketsSelector);
  const isLongTickets = useLayoutStore(isLongTicketsSelector);

  const layoutButtonsReference = useRef(0);

  function updatePosition() {
    if (!isPartOfTutorial) {
      setLayoutButtonsPosition({
        layoutButtonsPosition: layoutButtonsReference.current.getBoundingClientRect(),
      });
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallTickets, isLongTickets]);

  const sendDataLayer = (value) => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'sendEvent',
      eventCategory: 'Bingoklient',
      eventAction: 'Val av vy',
      eventLabel: `Vy ${value}`,
    });
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isLongTickets]: isLongTickets,
      })}
      ref={layoutButtonsReference}
    >
      <button
        type="button"
        className={clsx(styles.layoutButton, {
          [styles.isLongTickets]: isLongTickets,
        })}
        onClick={() => {
          sendDataLayer(1);
          setLayoutId(1);
        }}
      >
        {!isSmallTickets ? (
          <LayoutShape2
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 1,
              [styles.activeTutorial]:
                isPartOfTutorial && currentLayoutId !== 1,
            })}
          />
        ) : (
          <LayoutShape5
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 1,
              [styles.activeTutorial]:
                isPartOfTutorial && currentLayoutId !== 1,
            })}
          />
        )}
      </button>
      <button
        type="button"
        className={clsx(styles.layoutButton, {
          [styles.isLongTickets]: isLongTickets,
        })}
        onClick={() => {
          sendDataLayer(2);
          setLayoutId(2);
        }}
      >
        {!isLongTickets &&
          (!isSmallTickets ? (
            <LayoutShape1
              className={clsx(styles.layoutIcon, {
                [styles.active]: currentLayoutId === 2,
                [styles.activeTutorial]:
                  isPartOfTutorial && currentLayoutId !== 2,
              })}
            />
          ) : (
            <LayoutShape4
              className={clsx(styles.layoutIcon, {
                [styles.active]: currentLayoutId === 2,
                [styles.activeTutorial]:
                  isPartOfTutorial && currentLayoutId !== 2,
              })}
            />
          ))}
        {isLongTickets && (
          <LayoutShape3
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 2,
              [styles.activeTutorial]:
                isPartOfTutorial && currentLayoutId !== 2,
            })}
          />
        )}
      </button>
      <button
        type="button"
        className={clsx(styles.layoutButton, {
          [styles.isLongTickets]: isLongTickets,
        })}
        onClick={() => {
          sendDataLayer(3);
          setLayoutId(3);
        }}
      >
        {!isLongTickets &&
          (!isSmallTickets ? (
            <LayoutShape3
              className={clsx(styles.layoutIcon, {
                [styles.active]: currentLayoutId === 3,
                [styles.activeTutorial]:
                  isPartOfTutorial && currentLayoutId !== 3,
              })}
            />
          ) : (
            <LayoutShape6
              className={clsx(styles.layoutIcon, {
                [styles.active]: currentLayoutId === 3,
                [styles.activeTutorial]:
                  isPartOfTutorial && currentLayoutId !== 3,
              })}
            />
          ))}
        {isLongTickets && (
          <LayoutShape1
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 3,
              [styles.activeTutorial]:
                isPartOfTutorial && currentLayoutId !== 3,
            })}
          />
        )}
      </button>
    </div>
  );
};

LayoutButtons.propTypes = {
  isPartOfTutorial: bool,
};

LayoutButtons.defaultProps = {
  isPartOfTutorial: false,
};

export default LayoutButtons;
