import create from 'zustand';

const useNumbersDrawnStore = create((set) => ({
  numbers: [],
  resetNumbers: () => set({ numbers: [] }),
  addNumber: (number) =>
    set((state) => ({
      numbers: [...state.numbers, number],
    })),
  addNewNumbersList: (newList) =>
    set(() => ({
      numbers: newList,
    })),
}));

export default useNumbersDrawnStore;
