import React from 'react';
import clsx from 'clsx';
import { string, bool, func, node } from 'prop-types';

import Cross from 'assets/svg/cross.svg';

import styles from './Button.module.scss';

const Button = ({
  children,
  className,
  onClick,
  isSecondary,
  isTertiary,
  isClean,
  isIcon,
  isDisabled,
  isModalCloseButton,
}) => (
  <button
    type="button"
    disabled={isDisabled}
    className={clsx(className, styles.wrapper, {
      [styles.secondary]: isSecondary,
      [styles.tertiary]: isTertiary,
      [styles.clean]: isClean,
      [styles.iconButton]: isIcon,
      [styles.modalCloseButton]: isModalCloseButton,
    })}
    onClick={onClick}
  >
    {isModalCloseButton && <Cross className={styles.crossIcon} />}
    {children && children}
  </button>
);

Button.propTypes = {
  onClick: func.isRequired,
  className: string,
  children: node,
  isDisabled: bool,
  isSecondary: bool,
  isTertiary: bool,
  isClean: bool,
  isIcon: bool,
  isModalCloseButton: bool,
};

Button.defaultProps = {
  className: null,
  children: null,
  isDisabled: false,
  isSecondary: false,
  isTertiary: false,
  isClean: false,
  isIcon: false,
  isModalCloseButton: false,
};

export default Button;
