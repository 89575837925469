import React, { useLayoutEffect, useRef, useState } from 'react';
import { node, oneOfType, string, number } from 'prop-types';
import clsx from 'clsx';
import throttle from 'lodash.throttle';

import styles from './DragScroll.module.scss';

const DragScroll = ({
  children,
  classNameWrapper,
  classNameScrollbar,
  forceUpdate,
}) => {
  const sliderRef = useRef(null);

  const scrollbarRef = useRef(null);
  const scrollbarThumbRef = useRef(null);
  const [canScroll, setCanScroll] = useState(false);

  const [isDown, setIsDown] = useState(false);
  const [isDownOnScrollbar, setIsDownOnScrollbar] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const onMouseDown = (e) => {
    setIsDown(true);

    setStartX(e.pageX - sliderRef.current.offsetLeft);
    if (scrollbarRef.current.contains(e.target)) {
      setIsDownOnScrollbar(true);
      if (!scrollbarThumbRef.current.contains(e.target)) {
        const totalScrollElementWidth = scrollbarRef.current.clientWidth;
        const clickSpot = e.pageX - scrollbarRef.current.offsetLeft;
        const percent = Math.round((clickSpot / totalScrollElementWidth) * 100);
        const totalWidthSlider =
          sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
        const percentSliderValue = (percent * totalWidthSlider) / 100;
        sliderRef.current.scrollLeft = percentSliderValue;
      }
    }
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const onMouseUp = () => {
    setIsDown(false);
    setIsDragging(false);
    setIsDownOnScrollbar(false);
  };

  const onMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();

    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = x - startX;

    if (!isDownOnScrollbar) {
      // The user has to drag at least 5px to start dragging.
      const isOverThreshold = Math.abs(walk) > 5 || isDragging;

      if (isOverThreshold) {
        sliderRef.current.scrollLeft = scrollLeft - walk;

        setIsDragging(true);
      }
    } else {
      // The user has to drag at least 5px to start dragging.
      const isOverThreshold = Math.abs(walk) > 5 || isDragging;

      if (isOverThreshold) {
        const totalScrollElementWidth = scrollbarRef.current.clientWidth;
        const clickSpot = e.pageX - scrollbarRef.current.offsetLeft;
        const percent = Math.round((clickSpot / totalScrollElementWidth) * 100);
        const totalWidthSlider =
          sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
        const percentSliderValue = (percent * totalWidthSlider) / 100;
        sliderRef.current.scrollLeft = percentSliderValue;

        setIsDragging(true);
      }
    }
  };

  useLayoutEffect(() => {
    const slider = sliderRef.current;
    const track = scrollbarRef.current;
    const thumb = scrollbarThumbRef.current;

    const moveScrollbarThumb = () => {
      const transformX =
        (slider.scrollLeft / slider.scrollWidth) * track.offsetWidth;

      thumb.style.transform = `translate3d(${transformX}px, 0, 0)`;
    };

    const onResize = throttle(() => {
      const hasOverflow = slider.scrollWidth > slider.clientWidth;

      slider.scrollLeft = 0;

      setCanScroll(hasOverflow);

      if (hasOverflow) {
        const thumbWidth = (slider.clientWidth / slider.scrollWidth) * 100;

        thumb.style.width = `${thumbWidth}%`;

        moveScrollbarThumb();
      }
    }, 500);

    // create an Observer instance
    const resizeObserver = new ResizeObserver(onResize);

    resizeObserver.observe(slider);

    slider.addEventListener('scroll', moveScrollbarThumb);

    onResize();

    return () => {
      slider.removeEventListener('scroll', moveScrollbarThumb);
      resizeObserver.unobserve(slider);
    };
  }, [forceUpdate]);

  return (
    <div
      className={clsx(classNameWrapper, styles.wrapper, {
        [styles.canScroll]: canScroll,
        [styles.isDragging]: isDragging,
      })}
      role="grid"
      tabIndex="-1"
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <div ref={sliderRef} className={styles.slider}>
        <div className={styles.inner}>{children}</div>
      </div>

      <div
        ref={scrollbarRef}
        className={clsx(classNameScrollbar, styles.scrollbar, {
          [styles.isActive]: canScroll,
        })}
      >
        <div ref={scrollbarThumbRef} className={styles.scrollbarThumb} />
      </div>
    </div>
  );
};

DragScroll.propTypes = {
  children: node,
  classNameWrapper: string,
  classNameScrollbar: string,
  forceUpdate: oneOfType([string, number]),
};

DragScroll.defaultProps = {
  children: null,
  classNameWrapper: null,
  classNameScrollbar: null,
  forceUpdate: null,
};

export default DragScroll;
