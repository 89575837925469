/* eslint-disable no-param-reassign */
import create from 'zustand';
import produce from 'immer';

import { getBreakpointsConfig, DESKTOP } from 'hooks/useBreakpoint';

const initialBreakpoint = getBreakpointsConfig(window.innerWidth);
const isDesktop = initialBreakpoint === DESKTOP;

export const useChatStore = create((set) => ({
  lastUpdated: null,
  connectionKey: null,
  setConnectionKey: (value) => set(() => ({ connectionKey: value })),
  setLastUpdated: (value) => set(() => ({ lastUpdated: value })),
  isChatExpanded: isDesktop,
  setIsChatExpanded: (value) => set(() => ({ isChatExpanded: value })),
  currentRoom: null,
  setCurrentRoom: (room) => set(() => ({ currentRoom: room })),
  chatrooms: [],
  addNewChatroom: (room) =>
    set((state) => ({
      chatrooms: [...state.chatrooms, room],
    })),
  bingoRoomsIsVisible: false,
  setBingoRoomsVisibility: (val) => set(() => ({ bingoRoomsIsVisible: val })),
  addNewMessage: (index, message) =>
    set(
      produce((draft) => {
        draft.chatrooms[index].messages.push(message);
      })
    ),
  updateUnreadCount: (index) =>
    set(
      produce((draft) => {
        draft.chatrooms[index].unreadCount += 1;
      })
    ),
  resetUnreadCount: (index) =>
    set(
      produce((draft) => {
        draft.chatrooms[index].unreadCount = 0;
      })
    ),
  updateParticipants: (index, participants) =>
    set(
      produce((draft) => {
        draft.chatrooms[index].chatParticipants = participants;
      })
    ),
  removeChatroom: (id) =>
    set((state) => ({
      chatrooms: state.chatrooms.filter((r) => r.roomId !== id),
    })),
  updateChatrooms: (newList) =>
    set(() => ({
      chatrooms: newList,
    })),
  activeChatRequest: [],
  addActiveChatRequest: (req) =>
    set((state) => ({
      activeChatRequest: [...state.activeChatRequest, req],
    })),
  removeActiveChatRequest: (oldReq) =>
    set((state) => ({
      activeChatRequest: state.activeChatRequest.filter(
        (req) => req !== oldReq
      ),
    })),
}));

export default useChatStore;
