import create from 'zustand';

const useCountdownStore = create((set) => ({
  hours: '00',
  minutes: '00',
  seconds: '00',
  startOfGame: null,
  activeCountdownGame: null,
  setHours: (value) => set(() => ({ hours: value })),
  setMinutes: (value) => set(() => ({ minutes: value })),
  setSeconds: (value) => set(() => ({ seconds: value })),
  setStartOfGame: (value) => set(() => ({ startOfGame: value })),
  setActiveGame: (value) => set(() => ({ activeCountdownGame: value })),
}));

export default useCountdownStore;
