import React from 'react';

import text from 'polyglot/polyglot';
import { func } from 'prop-types';

import useGameStore, { GAME_REPLAY } from 'stores/game';
import useUserStore from 'stores/user';
import useTutorialStore from 'stores/tutorial';

import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';
import Button from 'components/Button/Button';
import LayoutButtons from 'components/TopbarPanel/LayoutButtons/LayoutButtons';
import SettingButtons from 'components/TopbarPanel/SettingButtons/SettingButtons';
import UserProfileContainer from 'components/TopbarPanel/UserProfileContainer/UserProfileContainer';

import logo from 'assets/theme/images/logo-desktop-98x41.png';

import styles from './DesktopView.module.scss';

const gameStateSelector = (state) => state.gameState;
const userSelector = (state) => state.user;
const setTutorialIsActiveSelector = (state) => state.setTutorialIsActive;
const setTutorialStepSelector = (state) => state.setTutorialStep;

const DesktopView = ({ modalVisibility }) => {
  const gameState = useGameStore(gameStateSelector);
  const user = useUserStore(userSelector);
  const setTutorialIsActive = useTutorialStore(setTutorialIsActiveSelector);
  const setTutorialStep = useTutorialStore(setTutorialStepSelector);
  const isReplay = gameState === GAME_REPLAY;

  return (
    <div className={styles.wrapper}>
      <div className={styles.userWrapper}>
        <UserProfileContainer />
      </div>
      <div className={styles.middleWrapper}>
        <div className={styles.settingsWrapper}>
          <SettingButtons />
          {user.isAuthenticated && !isReplay && (
            <Button
              className={styles.tutorialButton}
              isTertiary
              onClick={() => {
                setTutorialStep(1);
                setTutorialIsActive(true);
              }}
            >
              {text.t('topPanel.guideButton')}
            </Button>
          )}
        </div>
        <a href={user.returnButtonURL}>
          <img src={logo} alt="Miljonlotteriet" className={styles.logo} />
        </a>
        <LayoutButtons />
        <Button
          className={styles.responsibilityButton}
          isSecondary
          onClick={modalVisibility}
        >
          <span className={styles.buttonText}>
            {text.t('topPanel.responsibilitiesButton')}
          </span>
        </Button>
      </div>
      <div className={styles.gameWrapper}>
        {!isReplay && <UpcomingGameBanner />}
      </div>
    </div>
  );
};

DesktopView.propTypes = {
  modalVisibility: func.isRequired,
};

export default DesktopView;
