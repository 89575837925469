import React, { useRef, useLayoutEffect, useEffect } from 'react';

import useBreakpoint, {
  MOBILE,
  DESKTOP,
  TABLET_LANDSCAPE,
} from 'hooks/useBreakpoint';
import text from 'polyglot/polyglot';
import clsx from 'clsx';
import { func, bool } from 'prop-types';
import useMenuStore from 'stores/menu';
import { useChatStore } from 'stores/chat';
import useGameStore, { ACTIVE_GAME, GAME_REPLAY } from 'stores/game';
import usePrizePlanStore from 'stores/prizeplan-modal';
import useUpcomingGameStore from 'stores/upcoming-game';
import useUserStore from 'stores/user';
import usePurchaseStore from 'stores/purchase';
import tutorialStore from 'stores/tutorial';
import useLeaderboardStore from 'stores/leaderboard';
import useLayoutStore from 'stores/layout';

import Button from 'components/Button/Button';
import LeaderBoard from 'components/GameInfoPanel/Leaderboard/Leaderboard';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';
import LastDrawnBalls from 'components/GameInfoPanel/LastDrawnBalls/LastDrawnBalls';
import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';
import GameWallet from 'components/GameWallet/GameWallet';

import ChatIcon from 'assets/svg/chat-icon.svg';
import InfoIcon from 'assets/theme/svg/info-dark-background.svg';

import styles from './GameLayout.module.scss';

const upcomingGameSelector = (state) => [state.nextGame.cardPrice];
const isMenuExpandedSelector = (state) => state.isMenuExpanded;
const setIsChatExpandedSelector = (state) => state.setIsChatExpanded;
const setModalVisibilitySelector = (state) => state.setModalVisibility;
const gameStateSelector = (state) => state.gameState;
const userSelector = (state) => state.user;
const numberOfTicketsNextGameSelector = (state) =>
  state.numberOfTicketsNextGame;
const chatIconPositionSelector = (state) => state.setChatIconPosition;
const leaderboardSelector = (state) => state.leaderboard;
const isLongTicketsSelector = (state) => state.isLongTickets;

const GameLayout = ({ setIsPurchaseDuringGame, isSideMenuOpen }) => {
  const isMenuExpanded = useMenuStore(isMenuExpandedSelector);
  const setIsChatExpanded = useChatStore(setIsChatExpandedSelector);
  const setModalVisibility = usePrizePlanStore(setModalVisibilitySelector);
  const gameState = useGameStore(gameStateSelector);
  const [upcomingGamePrice] = useUpcomingGameStore(upcomingGameSelector);
  const user = useUserStore(userSelector);
  const isReplay = gameState === GAME_REPLAY;
  const numberOfTicketsNextGame = usePurchaseStore(
    numberOfTicketsNextGameSelector
  );
  const setChatIconPosition = tutorialStore(chatIconPositionSelector);
  const leaderboardList = useLeaderboardStore(leaderboardSelector);
  const isLongsTickets = useLayoutStore(isLongTicketsSelector);

  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === DESKTOP;
  const isActiveGame = gameState === ACTIVE_GAME;
  const chatRef = useRef(0);

  const setPurchaseView = () => {
    if (!user.isAuthenticated) {
      if (user.callback_login && typeof user.callback_login === 'function') {
        user.callback_login();
      }
      return;
    }
    if (gameState === ACTIVE_GAME) {
      setIsPurchaseDuringGame(true);
    }
  };

  function updatePosition() {
    if (!isDesktop && !isReplay) {
      setChatIconPosition({
        chatReference: chatRef.current.getBoundingClientRect(),
      });
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isActiveGame, leaderboardList]);

  useEffect(() => {
    setTimeout(() => {
      updatePosition();
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuExpanded]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isSideMenuOpen]: isSideMenuOpen,
      })}
    >
      <div
        className={clsx(styles.leftSection, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        <LastDrawnBalls />
        <div
          className={clsx(styles.prizes, {
            [styles.isExpandedView]: isMenuExpanded,
            [styles.isSideMenuOpen]: isSideMenuOpen,
            [styles.isLongsTickets]: isLongsTickets,
          })}
        >
          {!isReplay && (
            <Button
              isIcon
              onClick={() => setModalVisibility()}
              className={styles.infoIcon}
            >
              <img src={InfoIcon} alt="i" />
            </Button>
          )}
          <ListOfPrizes isExpandedView={isMenuExpanded} isReplay={isReplay} />
        </div>
      </div>
      <div
        className={clsx(styles.rightSection, {
          [styles.isExpandedView]: isMenuExpanded,
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        {!isReplay && (
          <>
            {breakpoint === MOBILE ? (
              <LeaderBoard leaderboardLength={isMenuExpanded ? 4 : 1} />
            ) : (
              <LeaderBoard leaderboardLength={breakpoint === DESKTOP ? 5 : 4} />
            )}
          </>
        )}
        {!isReplay && (
          <div
            className={clsx(styles.buyButtonContainer, {
              [styles.isExpandedView]: isMenuExpanded,
              [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
            })}
          >
            <Button className={styles.buyButton} onClick={setPurchaseView}>
              <span className={styles.bold}>
                {text.t('buyLotteryTicketButtons.buyButton')}
              </span>
              {upcomingGamePrice ? (
                <span className={styles.unBold}>
                  {text.t('buyLotteryTicketButtons.amount', {
                    amount: upcomingGamePrice,
                  })}
                </span>
              ) : (
                ''
              )}
            </Button>
            {numberOfTicketsNextGame && numberOfTicketsNextGame > 0 ? (
              <span className={styles.boughtCardsText}>
                {numberOfTicketsNextGame === 1
                  ? text.t('prepurchasedTickets.oneTicket', {
                      amount: numberOfTicketsNextGame,
                    })
                  : text.t('prepurchasedTickets.moreTickets', {
                      amount: numberOfTicketsNextGame,
                    })}
              </span>
            ) : null}
            <div className={styles.upcomingGame}>
              <UpcomingGameBanner nakedBanner />
            </div>
          </div>
        )}
        <div className={styles.walletContainer}>
          {user.isAuthenticated && breakpoint === TABLET_LANDSCAPE && (
            <div className={styles.gameWallet}>
              <GameWallet />
            </div>
          )}
          {!isReplay && (
            <Button
              isIcon
              onClick={() => setIsChatExpanded(true)}
              className={clsx(styles.chatButton, {
                [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
              })}
            >
              <div className={styles.chatIconWrapper} ref={chatRef}>
                <ChatIcon />
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

GameLayout.propTypes = {
  setIsPurchaseDuringGame: func.isRequired,
  isSideMenuOpen: bool,
};

GameLayout.defaultProps = {
  isSideMenuOpen: false,
};

export default GameLayout;
