import React from 'react';
import { func, bool } from 'prop-types';
import clsx from 'clsx';

import text from 'polyglot/polyglot';

import ChevronIcon from 'assets/svg/chevron.svg';

import styles from './ToggleButton.module.scss';

const ToggleButton = ({ isActive, onClick }) => (
  <div
    className={clsx(styles.wrapper, {
      [styles.isActive]: isActive,
    })}
  >
    <button
      type="button"
      className={styles.button}
      onClick={onClick}
      aria-label={text.t('chat.toggleButton')}
    >
      <ChevronIcon />
    </button>
  </div>
);

ToggleButton.propTypes = {
  isActive: bool,
  onClick: func,
};

ToggleButton.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default ToggleButton;
