import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import shallow from 'zustand/shallow';

import useMenuStore from 'stores/menu';
import useGameStore, { PURCHASE_VIEW } from 'stores/game';
import useGameStatsStore from 'stores/current-game';
import { useChatStore } from 'stores/chat';
import useUserStore from 'stores/user';
import bingoAssetsStore from 'stores/bingoAssetsStore';
import useBreakpoint, { MOBILE, TABLET_LANDSCAPE } from 'hooks/useBreakpoint';

import GameInfoBar from './GameInfoBar/GameInfoBar';
import UserBingoCards from './UserBingoCards/UserBingoCards';
import PurchaseBingoCards from './PurchaseBingoCards/PurchaseBingoCards';

import styles from './BingoPanel.module.scss';

const menuExpandedSelector = (state) => state.isMenuExpanded;
const menuOpenSelector = (state) => state.isMenuOpen;
const gameStateSelector = (state) => state.gameState;
const chatSelector = (state) => state.isChatExpanded;
const currentGameSelector = (state) => state.currentGame;
const userBaseUrlSelector = (state) => state.user.apiURL;
const assetsSelector = (state) => [state.assets, state.updateAssets];

const BingoPanel = () => {
  const isMenuExpanded = useMenuStore(menuExpandedSelector);
  const isMenuOpen = useMenuStore(menuOpenSelector);
  const isChatExpanded = useChatStore(chatSelector);
  const gameState = useGameStore(gameStateSelector);
  const currentGame = useGameStatsStore(currentGameSelector);
  const baseURL = useUserStore(userBaseUrlSelector);

  const isPurchase = gameState === PURCHASE_VIEW;
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === MOBILE;
  const isOpenSidemenu = isChatExpanded || isMenuOpen;

  const [background, setBackground] = useState('');
  const [logo, setLogo] = useState('');
  const [currentGameID, setGameID] = useState(null);
  const [assets, updateAssets] = bingoAssetsStore(assetsSelector, shallow);

  const collectBG = async () => {
    if (!assets[currentGame.backgroundBig]) {
      axios
        .get(`${baseURL}/${currentGame.backgroundBig}`)
        .then((res) => {
          setBackground(res.data);
          updateAssets({ [currentGame.backgroundBig]: res.data });
        })
        .catch((err) => {
          console.log('error on trying to get big background: ', err);
        });
    } else {
      setBackground(assets[currentGame.backgroundBig]);
    }
  };

  const collectBGsmall = async () => {
    if (!assets[currentGame.backgroundSmall]) {
      axios
        .get(`${baseURL}/${currentGame.backgroundSmall}`)
        .then((res) => {
          updateAssets({ [currentGame.backgroundSmall]: res.data });
        })
        .catch((err) => {
          console.log('error on trying to get big background: ', err);
        });
    }
  };

  const collectLogo = async () => {
    if (!assets[currentGame.logoBig]) {
      axios
        .get(`${baseURL}/${currentGame.logoBig}`)
        .then((res) => {
          setLogo(res.data);
          updateAssets({ [currentGame.logoBig]: res.data });
        })
        .catch((err) => {
          console.log('error on trying to get big logo: ', err);
        });
    } else {
      setLogo(assets[currentGame.logoBig]);
    }
  };

  const collectLogoSmall = async () => {
    if (!assets[currentGame.logoSmall]) {
      axios
        .get(`${baseURL}/${currentGame.logoSmall}`)
        .then((res) => {
          updateAssets({ [currentGame.logoSmall]: res.data });
        })
        .catch((err) => {
          console.log('error on trying to get big logo: ', err);
        });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (currentGame && currentGame.bingoRoundId !== currentGameID) {
      setGameID(currentGame.bingoRoundId);
      if (mounted) {
        collectBG();
        collectBGsmall();
        collectLogo();
        collectLogoSmall();
      }
    }

    // Cleanup
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isMenuExpanded]: isMenuExpanded || isPurchase,
        [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
      })}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div
        className={clsx(styles.infoBar, {
          [styles.isHidden]: isMobile && isOpenSidemenu && !isPurchase,
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        <GameInfoBar />
      </div>
      {logo ? (
        <img
          src={logo}
          alt=""
          className={clsx(styles.logo, {
            [styles.isHidden]: isMobile && isOpenSidemenu && !isPurchase,
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          })}
        />
      ) : (
        <div className={clsx(styles.logo, styles.minHeight)} />
      )}

      <div
        className={clsx(styles.cards, {
          [styles.isMenuExpanded]: isMenuExpanded || isPurchase,
          [styles.isSideMenuOpen]: isOpenSidemenu && !isPurchase,
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        {isPurchase ? (
          <PurchaseBingoCards isSideMenuOpen={isMobile && isOpenSidemenu} />
        ) : (
          <UserBingoCards isSideMenuOpen={isMobile && isOpenSidemenu} />
        )}
      </div>
    </div>
  );
};

export default BingoPanel;
