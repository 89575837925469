import React from 'react';
import { string, bool } from 'prop-types';
import clsx from 'clsx';

import useLayoutStore from 'stores/layout';

import styles from './BingoSquare.module.scss';

const isLongTicketsSelector = (state) => state.isLongTickets;

const BingoSquare = ({ type, isDark, isVertical, isGolden }) => {
  const isLongTicket = useLayoutStore(isLongTicketsSelector);
  const totalFields = isLongTicket ? 15 : 25;

  const collectFields = () => {
    const innerSquareIndex = [6, 7, 8, 11, 12, 13, 16, 17, 18];
    const onewRowIndexTop = [0, 1, 2, 3, 4];
    const onewRowIndex = [10, 11, 12, 13, 14];
    const oneRowVertical = [2, 7, 12, 17, 22];
    const oneRowCross = [0, 6, 12, 18, 24];
    const diamond = [2, 6, 7, 8, 10, 11, 12, 13, 14, 16, 17, 18, 22];
    const mSymbol = [0, 4, 5, 6, 8, 9, 10, 12, 14, 15, 19, 20, 24];
    const plus = [2, 7, 10, 11, 12, 13, 14, 17, 22];

    const elements = [];
    for (let i = 0; i < totalFields; i++) {
      elements.push(
        <div
          key={`square-${i}`}
          className={clsx(styles.field, {
            [styles.isVertical]: isVertical,
            [styles.darker]: isDark,
            [styles.filled]:
              type === 'fullcard' ||
              (type === '1rowhor' &&
                isLongTicket &&
                i <= 5 &&
                onewRowIndexTop.includes(i)) ||
              (type === '1rowhor' &&
                !isLongTicket &&
                i >= 5 &&
                onewRowIndex.includes(i)) ||
              (type === '2rowhor' && i < 10) ||
              (type === '3rowhor' && i < 15) ||
              (type === '4rowhor' && i < 20) ||
              (type === 'square' && !innerSquareIndex.includes(i)) ||
              (type === 'innerSquare' && innerSquareIndex.includes(i)) ||
              (type === 'diamond' && diamond.includes(i)) ||
              (type === '1rowvert' && oneRowVertical.includes(i)) ||
              (type === '1rowdiag' && oneRowCross.includes(i)) ||
              (type === 'm' && mSymbol.includes(i)) ||
              (type === 'plus' && plus.includes(i)),
            [styles.gold]: isGolden,
          })}
        />
      );
    }
    return elements;
  };
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isVertical]: isVertical,
        [styles.isSmallSquare]: totalFields === 15,
      })}
    >
      {collectFields()}
    </div>
  );
};

BingoSquare.propTypes = {
  type: string.isRequired,
  isDark: bool,
  isVertical: bool,
  isGolden: bool,
};

BingoSquare.defaultProps = {
  isDark: false,
  isVertical: false,
  isGolden: false,
};

export default BingoSquare;
