/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import shallow from 'zustand/shallow';

import useWinnerStatsStore from 'stores/winner-stats';
import useGameStatsStore from 'stores/current-game';
import SoundManager from 'utils/SoundManager';
import useUserStore from 'stores/user';
import useGameStore, { GAME_REPLAY } from 'stores/game';
import useCardRankingStore from 'stores/card-ranking';
import usePrizeTypesStore from 'stores/prize-types';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import BingoSquare from 'components/GameInfoPanel/ListOfPrizes/BingoSquare/BingoSquare';

import styles from './ScoreboardModal.module.scss';

const jackpotWinnersSelector = (state) => [state.scoreboard.jackpotWinners];
const winnersSelector = (state) => [state.scoreboard.winners];
const resetScoreboardSelector = (state) => state.resetScoreboard;
const userSelector = (state) => [state.user, state.setUser];
const currentGameSelector = (state) => state.currentGame;
const gameStateSelector = (state) => state.gameState;
const cardRankingSelector = (state) => state.cardRanking;
const prizeTypesSelector = (state) => state.prizes;

const ScoreboardModal = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [jackpotWinners] = useWinnerStatsStore(jackpotWinnersSelector);
  const [winners] = useWinnerStatsStore(winnersSelector);
  const resetScoreboard = useWinnerStatsStore(resetScoreboardSelector);
  const [currentUser, setUser] = useUserStore(userSelector, shallow);
  const currentGame = useGameStatsStore(currentGameSelector);
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;
  const cardranking = useCardRankingStore(cardRankingSelector);
  const [gameName] = useState(currentGame.name);
  const [gameVariant] = useState(currentGame.gameTypeName);
  const [jackpotValueDisplayName] = useState(
    currentGame.jackpots[0]?.jackpotValueDisplayName || null
  );
  const prizeTypes = usePrizeTypesStore(prizeTypesSelector);

  const onClick = () => {
    setIsHidden(true);
    resetScoreboard();
  };

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  const getHighestAmount = (array) =>
    array
      .map((item) =>
        item.winners.length > 1 ? item.winners : item.winners[0].amount
      )
      .flat()
      .reduce((prev, current) =>
        prev?.amount > current.amount ? prev.amount : current.amount
      );

  useEffect(() => {
    if (!isReplay) {
      if (currentUser.isAuthenticated && cardranking.length) {
        let balance = 0;
        const currentUserNickname = currentUser.nickname;
        winners.map((item) =>
          item.winners.forEach((user) => {
            if (user.name === currentUserNickname) {
              balance += user.amount;
            }
          })
        );

        jackpotWinners.map((item) =>
          item.winners.forEach((user) => {
            if (user.name === currentUserNickname) {
              balance += user.amount;
            }
            if (
              user.name === currentUserNickname &&
              user.amount === getHighestAmount(jackpotWinners)
            ) {
              if (
                currentUser.callback_jackpot &&
                typeof currentUser.callback_jackpot === 'function'
              ) {
                if (currentGame.jackpots.length) {
                  currentUser.callback_jackpot(
                    String(currentGame.bingoRoundId),
                    currentGame.name,
                    currentGame.jackpots[0]?.jackpotValueDisplayName
                      ? currentGame.jackpots[0].jackpotValueDisplayName
                      : user.amount
                  );
                } else {
                  currentUser.callback_jackpot(
                    String(currentGame.bingoRoundId),
                    currentGame.name,
                    user.amount
                  );
                }
              }
            }
          })
        );
        currentUser.callback_game_finished({
          gameId: String(currentGame.bingoRoundId),
          name: currentGame.name,
          ticketPrice: currentGame.cardPrice,
          priceAmount: balance,
        });
        if (balance > 0) {
          setUser({ updateBalance: true });
        }
      }
      SoundManager.instance.playVO('bingoend');

      // Clean up.
      return () => {
        SoundManager.instance.stopVO('bingoend');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isHidden) return null;

  return (
    <Modal onClickOutside={onClick} isPrizeModal>
      <div className={styles.content}>
        <Scrollbars className={styles.scrollbars}>
          <div className={styles.inner}>
            <h2 className={styles.title}>{`${gameName} - ${text.t(
              'winnerListModal.title'
            )}`}</h2>

            <table className={styles.table} cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header1')}
                    </span>
                  </th>
                  <th aria-hidden />
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header2')}
                    </span>
                  </th>
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header3')}
                    </span>
                  </th>
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header4')}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {winners.map((prizeItem) =>
                  prizeItem.winners.map((user, index) => (
                    <tr
                      key={`winner-row-${user.name + user.amount}`}
                      className={clsx({
                        [styles.isFirstRowOfType]: index === 0,
                        [styles.isFullRow]: index !== 0,
                      })}
                    >
                      <td>
                        {prizeTypes[gameVariant]
                          ? prizeTypes[gameVariant].label
                          : prizeItem.prizeTypeName}
                      </td>
                      <td>
                        <span className={styles.icon}>
                          {prizeItem.prizeTypeCode === '1rowverthordiag' ? (
                            <BingoSquare type="1rowhor" />
                          ) : (
                            <BingoSquare
                              type={
                                prizeTypes[gameVariant]
                                  ? prizeTypes[gameVariant].pattern
                                  : prizeItem.prizeTypeCode
                              }
                            />
                          )}
                        </span>
                      </td>
                      <td>{prizeItem.afterNumberOfBalls}</td>
                      <td>
                        {isReplay
                          ? user.amount === getHighestAmount(winners) &&
                            user.jackpotValueDisplayName &&
                            prizeItem.isJackpot
                            ? user.jackpotValueDisplayName
                            : new Intl.NumberFormat(
                                text.t('currencyLocale'),
                                settings
                              ).format(user.amount)
                          : new Intl.NumberFormat(
                              text.t('currencyLocale'),
                              settings
                            ).format(user.amount)}
                      </td>
                      <td>{user.name}</td>
                    </tr>
                  ))
                )}
                {jackpotWinners.map((prizeItem) =>
                  prizeItem.winners.map((user, index) => (
                    <tr
                      key={`winner-row-${user.name + user.amount}`}
                      className={clsx({
                        [styles.isJackpot]: true,
                        [styles.isFirstRowOfType]: index === 0,
                        [styles.isFullRow]: index !== 0,
                      })}
                    >
                      <td>
                        {prizeTypes[gameVariant]
                          ? prizeTypes[gameVariant].label
                          : prizeItem.prizeTypeName}
                      </td>
                      <td>
                        <span className={styles.icon}>
                          {prizeItem.prizeTypeCode === '1rowverthordiag' ? (
                            <BingoSquare type="1rowhor" />
                          ) : (
                            <BingoSquare
                              type={
                                prizeTypes[gameVariant]
                                  ? prizeTypes[gameVariant].pattern
                                  : prizeItem.prizeTypeCode
                              }
                            />
                          )}
                        </span>
                      </td>
                      <td>{prizeItem.afterNumberOfBalls}</td>
                      <td>
                        {jackpotValueDisplayName
                          ? jackpotValueDisplayName
                          : new Intl.NumberFormat(
                              text.t('currencyLocale'),
                              settings
                            ).format(user.amount)}
                      </td>
                      <td>{user.name}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        <Button isSecondary onClick={onClick} className={styles.closeButton}>
          {text.t('winnerListModal.closeButton')}
        </Button>
      </div>
    </Modal>
  );
};

export default ScoreboardModal;
