import React, { useEffect, useRef, useState } from 'react';
import { func } from 'prop-types';
import { useTransition, animated } from 'react-spring';
import { useClickAway } from 'react-use';
import useChatTextStore from 'stores/chat-text';

import { emojiList } from 'data/emojis';

import useUserStore from 'stores/user';

import Scrollbars from 'components/Scrollbars/Scrollbars';

import styles from './EmojiKeyboard.module.scss';

const userSelector = (state) => state.user;
const isFocusedSelector = (state) => state.setIsFocused;

const EmojiKeyboard = ({ onSelect }) => {
  const wrapperRef = useRef(null);
  const isOpenRef = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const setIsFocused = useChatTextStore(isFocusedSelector);
  const chatUser = useUserStore(userSelector);
  const firstEmoji = emojiList[0] ?? '🙂';

  const listTransition = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
  });

  const onToggleList = () => setIsOpen(!isOpen);

  const onClickOutside = () => setIsOpen(false);

  useClickAway(wrapperRef, onClickOutside);

  useEffect(() => {
    if (isOpen) {
      isOpenRef.current = true;
    } else {
      isOpenRef.current = false;
    }
  }, [isOpen]);

  const pressedKey = (e) => {
    if (e.key === 'Enter' && isOpenRef.current) {
      e.preventDefault();
      setIsOpen(false);
      setIsFocused(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', pressedKey);

    return () => {
      document.removeEventListener('keydown', pressedKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {listTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div className={styles.emojis} key={key} style={props}>
              <Scrollbars className={styles.scroll}>
                <div className={styles.list}>
                  {emojiList.map((emoji) => (
                    <button
                      key={`emoji-${emoji}`}
                      className={styles.emoji}
                      type="button"
                      onClick={onSelect}
                      disabled={!chatUser.isAuthenticated}
                    >
                      {emoji}
                    </button>
                  ))}
                </div>
              </Scrollbars>
            </animated.div>
          )
      )}
      <button
        type="button"
        className={styles.button}
        disabled={!chatUser.isAuthenticated}
        onClick={onToggleList}
      >
        {firstEmoji}
      </button>
    </div>
  );
};

EmojiKeyboard.propTypes = {
  onSelect: func,
};

EmojiKeyboard.defaultProps = {
  onSelect: () => {},
};

export default React.memo(EmojiKeyboard, () => true);
