import create from 'zustand';

import { toggleArrayValue } from 'utils/helpers';

const usePurchaseStore = create((set) => ({
  isLoading: false,
  bingoCardsList: [],
  selectedCardIds: [],
  prebuyTicketAmount: 0,
  numberOfTicketsCurrentGame: 0,
  numberOfTicketsNextGame: 0,
  fetchNewCards: false,
  setFetchNewCards: (newVal) =>
    set(() => ({
      fetchNewCards: newVal,
    })),
  setIsLoading: (isLoading) =>
    set(() => ({
      isLoading,
    })),
  setBingoCardsList: (cards) =>
    set(() => ({
      bingoCardsList: cards,
    })),
  resetSelectedCardIds: () => set({ selectedCardIds: [] }),
  updateSelectedCardIds: (cardId) =>
    set((state) => ({
      selectedCardIds: toggleArrayValue(state.selectedCardIds, cardId),
    })),
  updatePrebuyTicketAmount: (newNumber) =>
    set({ prebuyTicketAmount: newNumber }),
  updateTicketsCurrentGame: (val) => set({ numberOfTicketsCurrentGame: val }),
  updateTicketsNextGame: (val) => set({ numberOfTicketsNextGame: val }),
}));

export default usePurchaseStore;
