import React, { useEffect, useState } from 'react';
import { func, bool } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import useUserStore from 'stores/user';
import usePurchaseStore from 'stores/purchase';
import useCurrentGameStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import useApiErrorStore from 'stores/api-error';
import shallow from 'zustand/shallow';
import api from 'utils/api';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import ConfirmPurchase from './ConfirmPurchase/ConfirmPurchase';
import PurchaseReceipt from './PurchaseReceipt/PurchaseReceipt';
import IncuffientFunds from './IncuffientFunds/IncuffientFunds';

import styles from './IncuffientFunds/IncuffientFunds.module.scss';

const CHECKING_CUSTOMER_LIMITS = 'CHECKING_CUSTOMER_LIMITS';
const CONFIRM_PURCHASE = 'CONFIRM_PURCHASE';
const PURCHASE_RECEIPT = 'PURCHASE_RECEIPT';
const INCUFFIENT_FUNDS = 'INCUFFIENT_FUNDS';
const NO_GAME_LIMITS = 'NO_GAME_LIMITS';
const ERROR_FETCHING_GAMELIMITS = 'ERROR_FETCHING_GAMELIMITS';

const selectedCardIdSelector = (state) => [
  state.selectedCardIds,
  state.resetSelectedCardIds,
];
const bingoCardsListSelector = (state) => [
  state.bingoCardsList,
  state.setBingoCardsList,
];
const userSelector = (state) => [state.user, state.setUser];
const currentGameSelector = (state) => state.currentGame;
const prebuyTicketAmountSelector = (state) => [
  state.prebuyTicketAmount,
  state.updatePrebuyTicketAmount,
];
const upcomingGameSelector = (state) => state.nextGame;
const setApiError = (state) => state.setErrorMessage;
const updateTicketsCurrentGameSelector = (state) => [
  state.numberOfTicketsCurrentGame,
  state.updateTicketsCurrentGame,
];
const updateTicketsNextGameSelector = (state) => [
  state.numberOfTicketsNextGame,
  state.updateTicketsNextGame,
];
const setFetchNewCardsSelector = (state) => state.setFetchNewCards;

const PurchaseModal = ({ onClose, isPrebuy }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState(CHECKING_CUSTOMER_LIMITS);
  const [selectedCardIds, resetSelectedCardIds] = usePurchaseStore(
    selectedCardIdSelector,
    shallow
  );
  const [bingoCardsList, setBingoCardsList] = usePurchaseStore(
    bingoCardsListSelector,
    shallow
  );
  const currentGame = useCurrentGameStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(upcomingGameSelector);
  const [prebuyTicketAmount, setPrebuyTicketAmount] = usePurchaseStore(
    prebuyTicketAmountSelector,
    shallow
  );
  const [
    numberOfTicketsCurrentGame,
    updateTicketsCurrentGame,
  ] = usePurchaseStore(updateTicketsCurrentGameSelector);
  const [numberOfTicketsNextGame, updateTicketsNextGame] = usePurchaseStore(
    updateTicketsNextGameSelector,
    shallow
  );
  const cardPrice = isPrebuy ? nextGame.cardPrice : currentGame.cardPrice;
  const cardsCount = isPrebuy ? prebuyTicketAmount : selectedCardIds.length;
  const totalPrice = cardPrice * cardsCount;
  const [user, setUser] = useUserStore(userSelector, shallow);
  const setApiErrorMessage = useApiErrorStore(setApiError);
  const setFetchNewCards = usePurchaseStore(setFetchNewCardsSelector);

  const checkCustomerLimit = async () => {
    await api
      .get('/api/CustomerLimit')
      .then((res) => {
        if (res.length) {
          let userHasSetGameLimit = false;
          for (let i = 0; i < res.length; i++) {
            if (res[i].setByCustomer) {
              userHasSetGameLimit = true;
              break;
            }
          }
          setUser({ userHasSetGameLimits: userHasSetGameLimit });
          if (userHasSetGameLimit) {
            // display purchase confirmation
            setCurrentView(CONFIRM_PURCHASE);
          } else {
            // display error
            setCurrentView(NO_GAME_LIMITS);
          }
        } else {
          setCurrentView(ERROR_FETCHING_GAMELIMITS);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setCurrentView(ERROR_FETCHING_GAMELIMITS);
      });
  };

  useEffect(() => {
    if (user.userHasSetGameLimits) {
      setCurrentView(CONFIRM_PURCHASE);
    } else if (user.userHasSetGameLimits === false) {
      setCurrentView(NO_GAME_LIMITS);
    } else {
      checkCustomerLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickClose = () => {
    if (!isLoading) {
      if (currentView === PURCHASE_RECEIPT) {
        if (isPrebuy) {
          setPrebuyTicketAmount(0);
        } else {
          resetSelectedCardIds();
          setBingoCardsList([]);
          setFetchNewCards(true);
        }
      }
      // setCurrentView(CONFIRM_PURCHASE);

      onClose();
    }
  };

  const checkErrorMessage = (err) => {
    switch (err.data) {
      case 'Not_Enough_Funds_To_Pay':
        setCurrentView(INCUFFIENT_FUNDS);
        break;
      case 'Has_Exceeded_Loss_Limit':
        onClose();
        setApiErrorMessage(13);
        break;
      case 'Has_Exceeded_Time_Limit':
        onClose();
        setApiErrorMessage(14);
        break;
      case 'Has_Game_Stop':
        onClose();
        setApiErrorMessage(15);
        break;
      case 'Customer_Bingo_Blocked':
        onClose();
        setApiErrorMessage(16);
        break;
      case 'BingoRound_Is_Closed_For_Purchasing_Cards':
        onClose();
        setApiErrorMessage(17);
        break;
      default:
        onClose();
        setApiErrorMessage(10);
        break;
    }
  };

  const sendG4tracking = async (gameId, gameName, price, quantity) => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_ga='))
      ?.split('=')[1];

    const postData = {
      actualEventName: 'bingo_purchase',
      clientId: cookieValue || String(user.userId),
      userId: user.tracking_ga4?.userId
        ? user.tracking_ga4?.userId
        : String(user.userId),
      segment: 'Segment 1',
      pageLocation: document.location.href,
      pageTitle: document.title,
      transactionId: uuidv4(),
      value: price * quantity,
      tax: 0,
      shipping: 0,
      currency: user.tracking_ga4.currency,
      dataSource: 'server_measurement_protocol',
      items: [
        {
          gameId,
          gameName,
          price,
          quantity,
          category: 'Games',
          category2: 'Bingo',
          category3: '',
        },
      ],
    };

    await api
      .post('/api/Tracking/Purchase', postData)
      .then(() => {})
      .catch((err) => {
        console.log('err from g4 tracking: ', err);
      });
  };

  const onClickConfirm = async () => {
    setIsLoading(true);

    if (isPrebuy) {
      const data = {
        bingoRoundId: nextGame.bingoRoundId,
        numberOfCards: cardsCount,
      };
      await api
        .post('/api/Bingo/prebuy', data)
        .then(() => {
          if (user.tracking_ga4 && user.tracking_ga4.active) {
            sendG4tracking(
              nextGame.bingoGameId,
              nextGame.name,
              cardPrice,
              cardsCount
            );
          }
          if (
            user.callback_purchase &&
            typeof user.callback_purchase === 'function'
          ) {
            user.callback_purchase({
              gameId: String(nextGame.bingoGameId),
              name: nextGame.name,
              ticketPrice: cardPrice,
              quantity: cardsCount,
            });
          }
          updateTicketsNextGame(numberOfTicketsNextGame + cardsCount);
          setUser({ updateBalance: true });
          setIsLoading(false);
          setCurrentView(PURCHASE_RECEIPT);
        })
        .catch((err) => {
          setIsLoading(false);
          checkErrorMessage(err);
        });
    } else {
      const cardsToPurchase = [];
      await selectedCardIds.forEach((cardId) => {
        cardsToPurchase.push(
          bingoCardsList.find((element) => element.checksum === cardId)
        );
      });

      const data = {
        bingoRoundId: currentGame.bingoRoundId,
        cardsToBuy: cardsToPurchase,
      };

      await api
        .post('/api/Bingo', data)
        .then(() => {
          if (user.tracking_ga4 && user.tracking_ga4.active) {
            sendG4tracking(
              currentGame.bingoGameId,
              currentGame.name,
              cardPrice,
              cardsCount
            );
          }
          if (
            user.callback_purchase &&
            typeof user.callback_purchase === 'function'
          ) {
            user.callback_purchase({
              gameId: String(currentGame.bingoGameId),
              name: currentGame.name,
              ticketPrice: cardPrice,
              quantity: cardsCount,
            });
          }
          updateTicketsCurrentGame(numberOfTicketsCurrentGame + cardsCount);
          setUser({ updateBalance: true });
          setIsLoading(false);
          setCurrentView(PURCHASE_RECEIPT);
        })
        .catch((err) => {
          setIsLoading(false);
          checkErrorMessage(err);
        });
    }
  };

  const onClickLoadMoney = () => {
    if (user.callback_deposit && typeof user.callback_deposit === 'function') {
      user.callback_deposit();
    }
    onClickClose();
  };

  switch (currentView) {
    case PURCHASE_RECEIPT:
      return (
        <PurchaseReceipt
          onClose={onClickClose}
          isLoading={isLoading}
          cardPrice={cardPrice}
          cardsCount={cardsCount}
          totalPrice={totalPrice}
          username={user.nickname}
          bingoName={isPrebuy ? nextGame.name : currentGame.name}
        />
      );

    case INCUFFIENT_FUNDS:
      return (
        <IncuffientFunds
          onClose={onClickClose}
          onClickLoadMoney={onClickLoadMoney}
          isLoading={isLoading}
        />
      );

    case NO_GAME_LIMITS:
    case ERROR_FETCHING_GAMELIMITS:
      return (
        <Modal
          isLoading={isLoading}
          onClickOutside={onClose}
          title={
            currentView === NO_GAME_LIMITS
              ? 'Köp kan ej genomföras'
              : 'Något har gått fel'
          }
          content={
            <div className={styles.text}>
              <p>
                {currentView === NO_GAME_LIMITS
                  ? 'Du måste sätta insättningsgräns innan du kan spela.'
                  : 'Försök igen.'}
              </p>
            </div>
          }
          buttons={[
            <Button
              key="confirm-button"
              className={styles.button}
              onClick={onClickClose}
              isTertiary
            >
              OK
            </Button>,
          ]}
        />
      );

    default:
      return (
        <ConfirmPurchase
          onClose={onClickClose}
          onClickConfirm={onClickConfirm}
          isLoading={isLoading || currentView === CHECKING_CUSTOMER_LIMITS}
          cardPrice={cardPrice}
          cardsCount={cardsCount}
          totalPrice={totalPrice}
          bingoName={isPrebuy ? nextGame.name : currentGame.name}
        />
      );
  }
};

PurchaseModal.propTypes = {
  onClose: func,
  isPrebuy: bool.isRequired,
};

PurchaseModal.defaultProps = {
  onClose: () => {},
};

export default PurchaseModal;
