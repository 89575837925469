import React, { useState, useEffect } from 'react';
import shallow from 'zustand/shallow';

import text from 'polyglot/polyglot';
import useGameErrorStore from 'stores/game-error';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import styles from './GameErrorModal.module.scss';

const gameErrorSelector = (state) => [
  state.bingoName,
  state.setIsGameErrorVisible,
];

const GameErrorModal = () => {
  const [bingoName, setIsGameErrorVisible] = useGameErrorStore(
    gameErrorSelector,
    shallow
  );
  const [countdown, setCountdown] = useState(5);
  const [interval, updateInterval] = useState(null);

  const onClose = () => {
    updateInterval(clearInterval(interval));
    setIsGameErrorVisible(false);
  };

  const tick = () => {
    setCountdown((oldVal) => (oldVal === 0 ? oldVal : oldVal - 1));
  };

  useEffect(() => {
    if (countdown <= 0) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  useEffect(() => {
    updateInterval(setInterval(tick, 1000));
    return () => updateInterval(clearInterval(interval));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={styles.smallerMargins}
      onClickOutside={onClose}
      title={
        bingoName
          ? `${bingoName} - ${text.t('gameErrorModal.tooFewPlayers.title')}`
          : `${text.t('gameErrorModal.tooFewPlayers.title')}`
      }
      content={
        <div>
          <div>
            <p>{text.t('gameErrorModal.tooFewPlayers.content1')}</p>
          </div>
          <div>
            <p>{text.t('gameErrorModal.tooFewPlayers.content2')}</p>
          </div>
        </div>
      }
      buttons={[
        <Button key="cancel-button" onClick={onClose} isTertiary>
          <span>
            {text.t('gameErrorModal.tooFewPlayers.nextButton')} ({countdown})
          </span>
        </Button>,
      ]}
    />
  );
};

export default GameErrorModal;
