import React from 'react';
import ChatManager from 'socket/ChatManager';
import { useChatStore } from 'stores/chat';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { object, bool } from 'prop-types';
import text from 'polyglot/polyglot';

import styles from './PrivateChatReqModal.module.scss';

const removeActiveChatRequestSelector = (state) =>
  state.removeActiveChatRequest;

const PrivateChatReqModal = ({ request, cantBeAccepted }) => {
  const removeActiveChatRequest = useChatStore(removeActiveChatRequestSelector);
  const answerRequest = (answer) => {
    ChatManager.instance.answerRequest(request.requestId, answer);
    removeActiveChatRequest(request);
  };

  const modalButtons = [
    <Button
      key="cancel-button"
      className={styles.button}
      onClick={() => answerRequest(false)}
      isSecondary
    >
      {text.t('chat.modal.privateChatRequest.cancelButton')}
    </Button>,
    <Button
      key="confirm-button"
      className={styles.button}
      onClick={() => answerRequest(true)}
    >
      {text.t('chat.modal.privateChatRequest.confirmButton')}
    </Button>,
  ];

  const secondaryModalButtons = [
    <Button
      key="cancel-button"
      className={styles.button}
      onClick={() => answerRequest(false)}
      isSecondary
    >
      {text.t('chat.modal.privateChatRequest.okButton')}
    </Button>,
  ];

  const modalContent = (
    <div className={styles.reqText}>
      <p className={styles.reqUser}>{request.userName}</p>
      <p>{text.t('chat.modal.privateChatRequest.subTitle')}</p>
    </div>
  );

  const secondaryModalContent = (
    <div className={styles.reqText}>
      <p className={styles.reqUser}>{request.userName}</p>
      <p>{text.t('chat.modal.privateChatRequest.subTitle')}</p>
      <p>{text.t('chat.modal.privateChatRequest.secondarySubtitle')}</p>
    </div>
  );

  return (
    <Modal
      title={text.t('chat.modal.privateChatRequest.title')}
      content={cantBeAccepted ? secondaryModalContent : modalContent}
      buttons={cantBeAccepted ? secondaryModalButtons : modalButtons}
      className={styles.reqModal}
      // onClickOutside={onCloseModal}
    />
  );
};

PrivateChatReqModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  request: object.isRequired,
  cantBeAccepted: bool.isRequired,
};

export default PrivateChatReqModal;
