import React from 'react';
import { arrayOf, shape, number, string, func, bool } from 'prop-types';
import clsx from 'clsx';
import { useChatStore } from 'stores/chat';

import Avatar from 'components/Chat/Avatar/Avatar';

import styles from './RoomList.module.scss';

const bingoRoomsIsVisibleSelector = (state) => state.bingoRoomsIsVisible;

const RoomList = ({
  currentRoomId,
  rooms,
  isVisible,
  onClick,
  hasUserList,
}) => {
  const roomsAsc = [...rooms].reverse();
  const bingoRoomsIsVisible = useChatStore(bingoRoomsIsVisibleSelector);
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isVisible]: isVisible,
      })}
    >
      {roomsAsc.map(
        (room) =>
          room && (
            <button
              key={room.roomId}
              type="button"
              className={clsx(styles.tab, {
                [styles.isActive]:
                  currentRoomId === room.roomId && !hasUserList,
              })}
              onClick={() => onClick(room)}
            >
              <Avatar avatar={room.icon} />

              {(room.unreadCount > 0 && bingoRoomsIsVisible) ||
              (room.unreadCount > 0 && currentRoomId !== room.roomId) ? (
                <span className={styles.notice}>{room.unreadCount}</span>
              ) : null}
            </button>
          )
      )}
    </div>
  );
};

RoomList.propTypes = {
  currentRoomId: string,
  rooms: arrayOf(
    shape({
      roomId: string,
      icon: number,
      updateAt: number,
      isPrivate: bool,
      unreadCount: number,
    })
  ),
  isVisible: bool,
  onClick: func,
  hasUserList: bool.isRequired,
};

RoomList.defaultProps = {
  currentRoomId: null,
  rooms: [],
  isVisible: false,
  onClick: () => {},
};

export default RoomList;
