import React, { useEffect, useState } from 'react';
import { string, number } from 'prop-types';
import axios from 'axios';
import shallow from 'zustand/shallow';
import SoundManager from 'utils/SoundManager';
import useUpcomingGameStore from 'stores/upcoming-game';
import useUserStore from 'stores/user';
import bingoAssetsStore from 'stores/bingoAssetsStore';

import text from 'polyglot/polyglot';

import styles from './SmallerBanner.module.scss';

const upcomingGameSelector = (state) => state.nextGame;
const userBaseUrlSelector = (state) => state.user.apiURL;
const assetsSelector = (state) => [state.assets, state.updateAssets];

const SmallerBanner = ({ minutes, seconds, gameId }) => {
  const nextGame = useUpcomingGameStore(upcomingGameSelector);
  const [background, setBackground] = useState('');
  const [logo, setLogo] = useState('');
  const [lastPlayedId, setLastPlayedId] = useState('');
  const [nextGameName, setNextGameName] = useState(null);
  const baseURL = useUserStore(userBaseUrlSelector);
  const [assets, updateAssets] = bingoAssetsStore(assetsSelector, shallow);

  useEffect(() => {
    if (minutes === '00' && seconds === '30' && lastPlayedId !== gameId) {
      // Play sound
      SoundManager.instance.playVO('startsoon30');
      setLastPlayedId(gameId);
    }

    // Clean up.
    return () => {
      SoundManager.instance.stopVO('startsoon30');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds]);

  useEffect(() => {
    if (nextGame.name && nextGame.name !== nextGameName) {
      setNextGameName(nextGame.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextGame]);

  useEffect(() => {
    let mounted = true;
    if (nextGameName && mounted) {
      const retrieveImages = async () => {
        if (!assets[nextGame.logoSmall]) {
          axios
            .get(`${baseURL}/${nextGame.logoSmall}`)
            .then((res) => {
              if (mounted) {
                setLogo(res.data);
                updateAssets({ [nextGame.logoSmall]: res.data });
              }
            })
            .catch((err) => {
              if (mounted) {
                console.log('error on getting small logo: ', err);
              }
            });
        } else {
          setLogo(assets[nextGame.logoSmall]);
        }

        // collect logoBig for LargerBanner
        if (!assets[nextGame.logoBig]) {
          axios
            .get(`${baseURL}/${nextGame.logoBig}`)
            .then((res) => {
              if (mounted) {
                updateAssets({ [nextGame.logoBig]: res.data });
              }
            })
            .catch((err) => {
              if (mounted) {
                console.log('error on getting small logo: ', err);
              }
            });
        }

        if (!assets[nextGame.backgroundSmall]) {
          axios
            .get(`${baseURL}/${nextGame.backgroundSmall}`)
            .then((res) => {
              if (mounted) {
                setBackground(res.data);
                updateAssets({ [nextGame.backgroundSmall]: res.data });
              }
            })
            .catch((err) => {
              if (mounted) {
                console.log('error on getting small background: ', err);
              }
            });
        } else {
          setBackground(assets[nextGame.backgroundSmall]);
        }
      };
      retrieveImages();
    }

    // Cleanup
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextGameName]);

  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={styles.wrapper}
    >
      <img src={`${logo}`} alt="" className={styles.logo} />
      <div className={styles.textWrapper}>
        <span>{text.t('gameBanner.smallBannerTitle')}</span>
        <div className={styles.bold}>
          <span>{nextGame.name}</span>
          {nextGame.startsInSeconds !== -1000 ? (
            <span className={styles.time}>{`${minutes}:${seconds}`}</span>
          ) : (
            <span className={styles.time} />
          )}
        </div>
      </div>
    </div>
  );
};
SmallerBanner.propTypes = {
  minutes: string.isRequired,
  seconds: string.isRequired,
  gameId: number.isRequired,
};

export default SmallerBanner;
