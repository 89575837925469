/* eslint-disable no-nested-ternary */
import React from 'react';
import { number, bool } from 'prop-types';
import clsx from 'clsx';

import useBreakpoint, {
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
} from 'hooks/useBreakpoint';
import useHistoryStore from 'stores/history';
import useGameStatsStore from 'stores/current-game';
import useLayoutStore from 'stores/layout';

import Button from 'components/Button/Button';

import lottoIconLarge from 'assets/theme/svg/bingolott-icon-large.svg';
import lottoIconSmall from 'assets/theme/svg/bingolott-icon-small.svg';

import styles from './TotalBallsDrawn.module.scss';

const totalNumberSelector = (state) => state.currentGame.numberOfBalls;
const isLongTicketsSelector = (state) => state.isLongTickets;

const TotalBallsDrawn = ({ currentNumber, isWhite }) => {
  const breakpoint = useBreakpoint();
  const toggleIsHistoryVisible = useHistoryStore(
    (state) => state.toggleIsHistoryVisible
  );
  const totalNumber = useGameStatsStore(totalNumberSelector);
  const isLongsTickets = useLayoutStore(isLongTicketsSelector);

  const onClick = () => toggleIsHistoryVisible();

  return (
    <div className={clsx(styles.wrapper, { [styles.isWhite]: isWhite })}>
      <span className={styles.currentNumber}>{currentNumber}</span>/
      <span className={styles.totalNumber}>{totalNumber}</span>
      <Button
        className={clsx(styles.button, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          [styles.isLongsTickets]: isLongsTickets,
        })}
        onClick={onClick}
        isIcon
      >
        <img
          src={
            breakpoint === TABLET_PORTRAIT || breakpoint === TABLET_LANDSCAPE
              ? lottoIconSmall
              : lottoIconLarge
          }
          className={clsx(styles.icon, {
            [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
            [styles.isLongsTickets]: isLongsTickets,
          })}
          alt="lotto-icon"
        />
      </Button>
    </div>
  );
};

TotalBallsDrawn.propTypes = {
  currentNumber: number.isRequired,
  isWhite: bool.isRequired,
};

export default TotalBallsDrawn;
