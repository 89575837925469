import React from 'react';
import { string, number } from 'prop-types';
import clsx from 'clsx';
import { getUserProfileImg } from 'utils/getUserProfileImg';

import chatBubbleIcon from 'assets/theme/svg/userPictures/chat-bubble.svg';

import styles from './Avatar.module.scss';

const Avatar = ({ avatar, className }) => {
  const url = avatar === -2 ? chatBubbleIcon : getUserProfileImg(avatar);

  return (
    <div
      className={clsx(styles.wrapper, className, {
        [styles.isChatBubble]: avatar === -2,
      })}
    >
      {url && <img src={url} alt="" className={styles.image} />}
    </div>
  );
};

Avatar.propTypes = {
  avatar: number,
  className: string,
};

Avatar.defaultProps = {
  avatar: -1,
  className: null,
};

export default React.memo(Avatar, () => true);
