import create from 'zustand';

const useMenuStore = create((set) => ({
  isMenuExpanded: false,
  setIsMenuExpanded: (value) => set(() => ({ isMenuExpanded: value })),
  isMenuOpen: false,
  setIsMenuOpen: (value) => set(() => ({ isMenuOpen: value })),
}));

export default useMenuStore;
