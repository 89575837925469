import create from 'zustand';

const useCardRankingStore = create((set) => ({
  cardRanking: [],
  updateCardRanking: (newCardRanking) => set({ cardRanking: newCardRanking }),
  resetCardRanking: () =>
    set(() => ({
      cardRanking: [],
    })),
}));

export default useCardRankingStore;
