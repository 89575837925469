import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import text from 'polyglot/polyglot';

import useCurrentGameStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import usePrizeTypesStore from 'stores/prize-types';

import BingoSquare from './BingoSquare/BingoSquare';

import styles from './ListOfPrizes.module.scss';

const currentGameSelector = (state) => state.currentGame;
const nextPrizeLevelSelector = (state) => state.currentGame.nextPrizeLevel;
const nextGameSelector = (state) => state.nextGame;
const bingoRoundIdSelector = (state) => state.currentGame.bingoRoundId;
const prizeTypesSelector = (state) => state.prizes;

const ListOfPrizes = ({
  isExpandedView,
  purchaseView,
  isVertical,
  isReplay,
  displayNextGame,
}) => {
  const currentGame = useCurrentGameStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(nextGameSelector);
  const activeGame = displayNextGame ? nextGame : currentGame;
  const prizes = displayNextGame ? nextGame.prizes : currentGame.prizes;
  const activePrizeLevel = useCurrentGameStore(nextPrizeLevelSelector);
  const bingoRoundId = useCurrentGameStore(bingoRoundIdSelector);
  const prizeTypes = usePrizeTypesStore(prizeTypesSelector);

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  const activePrize =
    purchaseView || isReplay || displayNextGame ? null : activePrizeLevel;

  return (
    <div key={bingoRoundId} className={styles.wrapper}>
      {prizes &&
        prizes.map((item) => (
          <div
            key={`prize-${item.prizeTypeCode}-${item.aggregateAmount}`}
            className={clsx(
              styles.prizeContainer,
              `prize-${item.prizeTypeCode}-${item.aggregateAmount}`,
              {
                [styles.threeRows]: item.prizeTypeCode === '1rowverthordiag',
                [styles.dark]: activePrize && activePrize === item.orderNumber,
                [styles.isExpandedView]: isExpandedView,
                [styles.isVertical]: isVertical,
                [styles.isReplay]: isReplay,
                [styles.isActive]: activePrize === item.orderNumber,
              }
            )}
          >
            <span className={styles.background} />
            <span
              className={clsx(styles.text, {
                [styles.short]:
                  item.prizeTypeCode === '1rowverthordiag' ||
                  item.prizeTypeCode === '1rowhor' ||
                  item.prizeTypeCode === '1rowvert' ||
                  item.prizeTypeCode === '1rowdiag',
              })}
            >
              <span>
                {prizeTypes[activeGame.gameTypeName]
                  ? prizeTypes[activeGame.gameTypeName].label
                  : item.prizeTypeName}
              </span>
            </span>
            {item.prizeTypeCode === '1rowverthordiag' ? (
              <span className={styles.icon}>
                <BingoSquare
                  type="1rowhor"
                  isDark={activePrize === item.orderNumber}
                  isVertical={isVertical}
                />
                {!isVertical && (
                  <BingoSquare
                    type="1rowvert"
                    isDark={activePrize === item.orderNumber}
                  />
                )}
                {!isVertical && (
                  <BingoSquare
                    type="1rowdiag"
                    isDark={activePrize === item.orderNumber}
                  />
                )}
              </span>
            ) : (
              <span className={styles.icon}>
                <BingoSquare
                  type={
                    prizeTypes[activeGame.gameTypeName]
                      ? prizeTypes[activeGame.gameTypeName].pattern
                      : item.prizeTypeCode
                  }
                  isDark={activePrize === item.orderNumber}
                  isVertical={isVertical}
                />
              </span>
            )}
            <span className={styles.prize}>
              {new Intl.NumberFormat(text.t('currencyLocale'), settings).format(
                item.aggregateAmount
              )}
            </span>
          </div>
        ))}
    </div>
  );
};

ListOfPrizes.propTypes = {
  isExpandedView: bool,
  purchaseView: bool,
  isVertical: bool,
  isReplay: bool,
  displayNextGame: bool,
};

ListOfPrizes.defaultProps = {
  isExpandedView: false,
  purchaseView: false,
  isVertical: false,
  isReplay: false,
  displayNextGame: false,
};
export default ListOfPrizes;
