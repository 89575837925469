import React, { useEffect } from 'react';
import { func } from 'prop-types';
import text from 'polyglot/polyglot';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import useMenuStore from 'stores/menu';
import useTutorialStore from 'stores/tutorial';

import Cogwheel from 'assets/svg/settings-cogwheel.svg';
import Dialogue from '../Dialogue/Dialogue';
import styles from './Step2Settings.module.scss';

const setIsMenuOpenSelector = (state) => state.setIsMenuOpen;
const cogwheelPositionSelector = (state) => state.cogwheelPosition;

const Step2Settings = ({ nextStep, closeTutorial }) => {
  const setIsMenuOpen = useMenuStore(setIsMenuOpenSelector);
  const cogwheelPosition = useTutorialStore(cogwheelPositionSelector);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (breakpoint) {
      if (breakpoint === DESKTOP) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoint]);

  return (
    <div
      className={styles.wrapper}
      style={{ top: cogwheelPosition.y || 0, left: cogwheelPosition.x || 0 }}
    >
      <p className={styles.settingsText}>{text.t('topPanel.listItem4')}</p>
      <Cogwheel className={styles.cogwheel} />
      <Dialogue
        className={styles.dialogue}
        text={text.t('tutorial.step2Text')}
        nextStep={nextStep}
        closeTutorial={closeTutorial}
        hasCloseButtonLeft={breakpoint !== DESKTOP}
        hasBottomArrow={breakpoint !== DESKTOP}
        hasMiddleLeftPositionedArrow={breakpoint !== DESKTOP}
        reversedTransition={breakpoint !== DESKTOP}
      />
    </div>
  );
};

Step2Settings.propTypes = {
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
};

export default Step2Settings;
