import React, { useEffect, useState } from 'react';
import { string, bool, func } from 'prop-types';
import shallow from 'zustand/shallow';
import text from 'polyglot/polyglot';
import ChatManager from 'socket/ChatManager';
import useChatTextStore from 'stores/chat-text';
import { useChatStore } from 'stores/chat';
import useUserStore from 'stores/user';
import bingoAssetsStore from 'stores/bingoAssetsStore';
import clsx from 'clsx';
import axios from 'axios';

import Modal from 'components/Modal/Modal';
import Avatar from 'components/Chat/Avatar/Avatar';
import Button from 'components/Button/Button';

import UserIcon from 'assets/svg/user.svg';

import UserList from './UserList/UserList';

import styles from './GroupChatHeader.module.scss';

const currentUserSelector = (state) => state.user;
const chatroomsSelector = (state) => state.chatrooms;
const userIsBlockedSelector = (state) => state.userIsBlocked;
const lastUpdatedSelector = (state) => state.lastUpdated;
const assetsSelector = (state) => [state.assets, state.updateAssets];
const userBaseUrlSelector = (state) => state.user.apiURL;

const GroupChatHeader = ({
  currentRoomId,
  cantRequest,
  hasUserList,
  setHasUserList,
  chathostImg,
  setChathostImg,
}) => {
  const isExpanded = useChatStore((state) => state.isChatExpanded);

  const currentUser = useUserStore(currentUserSelector);
  const [hasModal, setHasModal] = useState(false);
  const [requestedUser, setRequestedUser] = useState(null);
  const chatrooms = useChatStore(chatroomsSelector);
  const [admin, setAdmin] = useState(null);
  const [regularUsers, setRegularUsers] = useState([]);
  const userIsBlocked = useChatTextStore(userIsBlockedSelector);
  const lastUpdated = useChatStore(lastUpdatedSelector);
  const [assets, updateAssets] = bingoAssetsStore(assetsSelector, shallow);
  const baseURL = useUserStore(userBaseUrlSelector);

  useEffect(() => {
    if (currentRoomId) {
      const newRoomIndex = chatrooms.findIndex(
        (room) => room.roomId === currentRoomId
      );
      setAdmin(
        chatrooms[newRoomIndex].chatParticipants.find((u) => u.isChatHost)
      );
      setRegularUsers(
        chatrooms[newRoomIndex].chatParticipants.filter(
          (u) => !u.isChatHost && u.nickname !== currentUser.nickname
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId, lastUpdated]);

  const onClickUserList = () => setHasUserList(!hasUserList);

  const onClickUser = (userName) => {
    if (!currentUser.isAuthenticated || userIsBlocked) return;
    setHasUserList(false);
    setRequestedUser(userName);
    setHasModal(true);
  };

  const onCloseModal = () => {
    setHasModal(false);
    setRequestedUser(null);
  };

  const sendRequestToUser = () => {
    ChatManager.instance.requestPrivateChat(requestedUser);
    onCloseModal();
  };

  const modalContent = (
    <div className={styles.reqText}>
      <p>{text.t('chat.modal.startPrivateChat.text')}</p>
      <p>
        <b className={styles.reqUser}>{requestedUser}</b>?
      </p>
    </div>
  );

  const secondaryModalContent = (
    <div className={styles.reqText}>
      <p>{text.t('chat.modal.startPrivateChat.secondaryText')}</p>
    </div>
  );

  const modalButtons = [
    <Button
      key="cancel-button"
      className={styles.button}
      onClick={onCloseModal}
      isSecondary
    >
      {text.t('chat.modal.startPrivateChat.cancelButton')}
    </Button>,
    <Button
      key="confirm-button"
      className={styles.button}
      onClick={sendRequestToUser}
    >
      {text.t('chat.modal.startPrivateChat.confirmButton')}
    </Button>,
  ];

  const secondaryModalButtons = [
    <Button
      key="cancel-button"
      className={styles.button}
      onClick={onCloseModal}
      isSecondary
    >
      {text.t('chat.modal.startPrivateChat.okButton')}
    </Button>,
  ];

  // Close user list on toggle.
  useEffect(() => {
    if (hasUserList && !isExpanded) {
      setHasUserList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserList, isExpanded]);

  const retrieveChathostImg = async () => {
    if (!assets[admin.chatHostImage]) {
      axios
        .get(`${baseURL}/${admin.chatHostImage}`)
        .then((res) => {
          setChathostImg(res.data.imageData);
          updateAssets({
            [admin.chatHostImage]: res.data.imageData,
          });
        })
        .catch((err) => {
          console.log('error on getting chathost image: ', err);
        });
    } else {
      setChathostImg(assets[admin.chatHostImage]);
    }
  };

  useEffect(() => {
    if (admin && admin.chatHostImage) {
      retrieveChathostImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  return (
    <>
      <div
        className={clsx(styles.wrapper, {
          [styles.hasAdmin]: admin,
        })}
      >
        {admin && (
          <div className={styles.admin}>
            {chathostImg ? (
              <div className={styles.chathostImgWrapper}>
                <img src={chathostImg} alt="" />
              </div>
            ) : (
              <Avatar avatar={admin.avatarId} className={styles.adminAvatar} />
            )}

            <div className={styles.adminInfo}>
              <span className={styles.adminLabel}>{text.t('chat.admin')}</span>
              &nbsp;
              <span className={styles.adminName}>{admin.nickname}</span>
            </div>
          </div>
        )}

        <button
          className={styles.usersButton}
          type="button"
          onClick={onClickUserList}
          disabled={!regularUsers.length}
        >
          <span
            className={styles.userCount}
          >{`${regularUsers.length} st`}</span>
          <UserIcon className={styles.userIcon} />
        </button>
      </div>

      {hasModal && (
        <Modal
          title={text.t('chat.modal.startPrivateChat.title')}
          content={cantRequest ? secondaryModalContent : modalContent}
          buttons={cantRequest ? secondaryModalButtons : modalButtons}
          // onClickOutside={onCloseModal}
        />
      )}

      {regularUsers.length > 0 && (
        <UserList
          users={regularUsers}
          isOpen={hasUserList}
          onClick={onClickUser}
        />
      )}
    </>
  );
};

GroupChatHeader.propTypes = {
  currentRoomId: string.isRequired,
  cantRequest: bool.isRequired,
  hasUserList: bool.isRequired,
  setHasUserList: func.isRequired,
  chathostImg: string.isRequired,
  setChathostImg: func.isRequired,
};

export default GroupChatHeader;
