import create from 'zustand';

const usePrizeTypesStore = create(() => ({
  prizes: {
    Diamant: {
      pattern: 'diamond',
      label: 'Diamant',
    },
    'Yttre ram': {
      pattern: 'square',
      label: 'Yttre ram',
    },
    'Inre ram': {
      pattern: 'innerSquare',
      label: 'Inre ram',
    },
    M: {
      pattern: 'm',
      label: 'M',
    },
    Plus: {
      pattern: 'plus',
      label: 'Plus',
    },
  },
}));

export default usePrizeTypesStore;
