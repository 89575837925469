import create from 'zustand';

const useHistoryStore = create((set) => ({
  isHistoryVisible: false,
  setIsHistoryVisible: (value) => set(() => ({ isHistoryVisible: value })),
  toggleIsHistoryVisible: () =>
    set((state) => ({ isHistoryVisible: !state.isHistoryVisible })),
}));

export default useHistoryStore;
