import React from 'react';
import { func, string, bool, shape } from 'prop-types';
import lang from 'polyglot/polyglot';
import clsx from 'clsx';

import Button from 'components/Button/Button';
import ChevronIcon from 'assets/svg/arrowhead-no-fill.svg';
import ArrowHead from 'assets/svg/tutorial-triangle.svg';

import styles from './Dialogue.module.scss';

const Dialogue = ({
  className,
  text,
  nextStep,
  closeTutorial,
  hasCloseButtonLeft,
  hasBottomArrow,
  hasRightPositionedArrow,
  hasFarRightPositionedArrow,
  hasLeftPositionedArrow,
  hasMiddleLeftPositionedArrow,
  customStyling,
  reversedTransition,
  delayTransition,
  isLastStep,
}) => (
  <div className={clsx(styles.wrapper, className)} style={customStyling}>
    {hasCloseButtonLeft && (
      <Button
        className={clsx(styles.closeButton, styles.transition, {
          [styles.isReversed]: reversedTransition,
          [styles.isDelayed]: delayTransition,
        })}
        onClick={closeTutorial}
        isModalCloseButton
      />
    )}
    <div
      className={clsx(styles.dialogueBox, styles.transition, {
        [styles.isReversed]: reversedTransition,
        [styles.isDelayed]: delayTransition,
      })}
    >
      <ArrowHead
        className={clsx(styles.arrowIcon, {
          [styles.hasBottomArrow]: hasBottomArrow,
          [styles.hasRightPositionedArrow]: hasRightPositionedArrow,
          [styles.hasFarRightPositionedArrow]: hasFarRightPositionedArrow,
          [styles.hasLeftPositionedArrow]: hasLeftPositionedArrow,
          [styles.hasMiddleLeftPositionedArrow]: hasMiddleLeftPositionedArrow,
        })}
      />
      <p>{text}</p>
      <Button
        onClick={isLastStep ? closeTutorial : nextStep}
        isClean
        className={styles.nextButton}
      >
        {isLastStep
          ? lang.t('tutorial.lastStepButton')
          : lang.t('tutorial.nextStepButton')}
        {!isLastStep && <ChevronIcon className={styles.icon} />}
      </Button>
    </div>
    {!hasCloseButtonLeft && (
      <Button
        className={clsx(styles.closeButton, styles.transition, {
          [styles.isReversed]: reversedTransition,
          [styles.isDelayed]: delayTransition,
        })}
        onClick={closeTutorial}
        isModalCloseButton
      />
    )}
  </div>
);

Dialogue.propTypes = {
  className: string,
  text: string.isRequired,
  nextStep: func.isRequired,
  closeTutorial: func.isRequired,
  hasCloseButtonLeft: bool,
  hasBottomArrow: bool,
  hasRightPositionedArrow: bool,
  hasFarRightPositionedArrow: bool,
  hasLeftPositionedArrow: bool,
  hasMiddleLeftPositionedArrow: bool,
  customStyling: shape(),
  reversedTransition: bool,
  delayTransition: bool,
  isLastStep: bool,
};

Dialogue.defaultProps = {
  className: '',
  hasCloseButtonLeft: false,
  hasBottomArrow: false,
  hasRightPositionedArrow: false,
  hasFarRightPositionedArrow: false,
  hasLeftPositionedArrow: false,
  hasMiddleLeftPositionedArrow: false,
  customStyling: {},
  reversedTransition: false,
  delayTransition: false,
  isLastStep: false,
};

export default Dialogue;
