/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { func } from 'prop-types';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import useGameStatsStore from 'stores/current-game';
import usePrizeTypesStore from 'stores/prize-types';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import Button from 'components/Button/Button';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import Modal from 'components/Modal/Modal';
import BingoSquare from 'components/GameInfoPanel/ListOfPrizes/BingoSquare/BingoSquare';

import styles from './PrizePlan.module.scss';

const currentGameSelector = (state) => state.currentGame;
const prizeTypesSelector = (state) => state.prizes;

const PrizePlan = ({ closeModal }) => {
  const currentGame = useGameStatsStore(currentGameSelector);
  const prizeTypes = usePrizeTypesStore(prizeTypesSelector);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'sendEvent',
      eventCategory: 'Bingo',
      eventAction: 'Info om bingospel',
      eventLabel: currentGame.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  const infoRow = [
    {
      text: text.t('prizeplan.priceText'),
      value: `${currentGame.cardPrice} kr`,
    },
    {
      text: text.t('prizeplan.numberOfPlayersText'),
      value: `${currentGame.numberOfPlayers} st`,
    },
    {
      text: text.t('prizeplan.totalPottText'),
      value: currentGame.pot
        ? new Intl.NumberFormat(text.t('currencyLocale'), settings).format(
            currentGame.pot
          )
        : '0 kr',
    },
    {
      text: text.t('prizeplan.withinBallsText'),
      value: `${currentGame.numberOfBalls} st`,
    },
  ];

  const headerRow = [
    text.t('prizeplan.header1'),
    text.t('prizeplan.header2'),
    text.t('prizeplan.header3'),
    text.t('prizeplan.header4'),
  ];

  return (
    <Modal onClickOutside={closeModal}>
      <Button
        className={styles.outerButton}
        onClick={closeModal}
        isModalCloseButton
      />
      <div className={styles.wrapper}>
        <Button
          className={styles.innerButton}
          onClick={closeModal}
          isModalCloseButton
        />
        <Scrollbars className={styles.scrollContainer}>
          <p className={styles.title}>
            {text.t('prizeplan.title', { name: currentGame.name })}
          </p>
          <div className={styles.infoContainer}>
            {infoRow.map((row) => (
              <div className={styles.infoBox} key={row.text + row.value}>
                <span>{row.text}</span>
                <span className={styles.bold}>{row.value}</span>
              </div>
            ))}
          </div>
          <div className={styles.prizeContainer}>
            <div className={clsx(styles.header, styles.prizeBox)}>
              {headerRow.map((header, index) => (
                <span
                  key={header}
                  className={clsx(styles.headerText, styles[`item-${index}`])}
                >
                  {header}
                </span>
              ))}
            </div>
            {currentGame.prizes.map((prize) => (
              <div key={prize.prizeTypeCode} className={styles.prizeBox}>
                <span
                  className={clsx(styles['item-0'], {
                    [styles.short]:
                      prize.prizeTypeCode === '1rowverthordiag' ||
                      prize.prizeTypeCode === '1rowhor' ||
                      prize.prizeTypeCode === '1rowvert' ||
                      prize.prizeTypeCode === '1rowdiag',
                  })}
                >
                  <span>
                    {prizeTypes[currentGame.gameTypeName]
                      ? prizeTypes[currentGame.gameTypeName].label
                      : prize.prizeTypeName}
                  </span>
                </span>
                <span className={clsx(styles.iconWrapper, styles['item-1'])}>
                  {prize.prizeTypeCode === '1rowverthordiag' ? (
                    <>
                      <BingoSquare type="1rowhor" />
                      <BingoSquare type="1rowvert" />
                      <BingoSquare type="1rowdiag" />
                    </>
                  ) : (
                    <BingoSquare
                      type={
                        prizeTypes[currentGame.gameTypeName]
                          ? prizeTypes[currentGame.gameTypeName].pattern
                          : prize.prizeTypeCode
                      }
                    />
                  )}
                </span>
                <span className={styles['item-2']}>{`${prize.percent}%`}</span>
                <span className={styles['item-3']}>
                  {new Intl.NumberFormat(
                    text.t('currencyLocale'),
                    settings
                  ).format(prize.aggregateAmount)}
                </span>
              </div>
            ))}
            {currentGame.jackpots.map((jackpot) => (
              <div
                key={jackpot.jackpotId}
                className={clsx(styles.prizeBox, styles.jackpott)}
              >
                <span className={styles['item-0']}>
                  {breakpoint === MOBILE
                    ? text.t('prizeplan.jackpotText1')
                    : text.t('prizeplan.jackpotFullText', {
                        number: jackpot.triggerNumberOfDraws,
                      })}
                </span>
                <span className={clsx(styles.iconWrapper, styles['item-1'])}>
                  {jackpot.prizeTypeCode === '1rowverthordiag' ? (
                    <>
                      <BingoSquare type="1rowhor" isGolden />
                      <BingoSquare type="1rowvert" isGolden />
                      <BingoSquare type="1rowdiag" isGolden />
                    </>
                  ) : (
                    <BingoSquare
                      type={
                        prizeTypes[currentGame.gameTypeName]
                          ? prizeTypes[currentGame.gameTypeName].pattern
                          : jackpot.prizeTypeCode
                      }
                      isGolden
                    />
                  )}
                </span>
                <span className={styles['item-2']}>
                  {breakpoint !== MOBILE
                    ? null
                    : text.t('prizeplan.jackpotText2', {
                        number: jackpot.triggerNumberOfDraws,
                      })}
                </span>
                <span className={styles['item-3']}>
                  {currentGame.jackpots[0].jackpotValueDisplayName
                    ? currentGame.jackpots[0].jackpotValueDisplayName
                    : jackpot.amount
                    ? new Intl.NumberFormat(
                        text.t('currencyLocale'),
                        settings
                      ).format(jackpot.amount)
                    : '0 kr'}
                </span>
              </div>
            ))}
            <div className={styles.rules}>
              <p className={styles.subtitle}>{text.t('prizeplan.subtitle')}</p>
              <p
                className={styles.content}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: currentGame.rules }}
              />
            </div>
          </div>
        </Scrollbars>
        <div className={styles.gradient} />
      </div>
    </Modal>
  );
};

PrizePlan.propTypes = {
  closeModal: func.isRequired,
};

export default PrizePlan;
