/* eslint-disable no-underscore-dangle */
import { Howl } from 'howler';
// import spriteData from 'data/soundSprite';
import voSpriteMP3 from 'assets/theme/sound/default.mp3';
import axios from 'axios';
import defaultJSON from 'assets/theme/sound/default.json';

export default class SoundManager {
  constructor() {
    if (this._instance) {
      return SoundManager.instance;
    }

    this.isMuted = false;
    this.announcerSound = false;
    this.voSprite = null;
    this.spriteData = null;
    this.lastPlayedVO = null;
    this.browserIsInteracted = false;

    const getData = () => {
      axios
        .get(defaultJSON, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((response) => {
          this.spriteData = response.data;
          this.voSprite = new Howl({
            src: [voSpriteMP3],
            sprite: this.spriteData,
            onunlock: () => {
              this.browserIsInteracted = true;
            },
          });
        })
        .catch((error) => {
          // handle error
          console.log('error on retrieving sprite data', error);
        });
    };

    getData();

    return this;
  }

  static get instance() {
    if (!this._instance) {
      this._instance = new SoundManager();
    }

    return this._instance;
  }

  playVO(id) {
    if (this.browserIsInteracted) {
      if (this.voSprite && id !== this.lastPlayedVO) {
        if (!this.isMuted && this.announcerSound) {
          this.voSprite.play(id);
        }
        if (id !== 'bingoend' && id !== 'bingo' && id !== 'jackpot') {
          this.lastPlayedVO = id;
        }
      }
    }
  }

  stopVO(id) {
    if (this.voSprite) {
      this.voSprite.stop(id);
    }
  }

  muteApp(value) {
    this.isMuted = value;
  }

  updateAnnouncerSound(value) {
    this.announcerSound = value;
  }

  startBgSound() {}

  stopBgSound() {}
}
