/* eslint-disable react/no-array-index-key */

import React from 'react';
import clsx from 'clsx';
import {
  string,
  oneOfType,
  arrayOf,
  bool,
  func,
  number,
  object,
} from 'prop-types';

import Star from 'assets/svg/star-simple.svg';
import useLayoutStore from 'stores/layout';
import useGameStore, { ACTIVE_GAME } from 'stores/game';

import NumberBlock from './NumberBlock/NumberBlock';

import styles from './Numbers.module.scss';

const longTicketSelector = (state) => state.isLongTickets;
const freeSquareBingoSelector = (state) => state.isFreeSquareBingo;
const gameStateSelector = (state) => state.gameState;

const Numbers = ({
  id,
  numbersMap,
  markedNumbers,
  currentSymbol,
  currentSymbolColorId,
  isHistory,
  onClick,
  winningNumbers,
  isLongTicketHistoryCard,
}) => {
  const isLongTicket = useLayoutStore(longTicketSelector);
  const isFreeSquareBingo = useLayoutStore(freeSquareBingoSelector);
  const gameState = useGameStore(gameStateSelector);
  const isActiveGame = gameState === ACTIVE_GAME;

  const emptyBlock = () => {
    if (isFreeSquareBingo) {
      return (
        <Star
          className={styles.emptyBlockIcon}
          viewBox="0 0 18 17"
          fill="var(--theme-color-1)"
        />
      );
    }
    return '';
  };

  const isActive = (blockInUse, blockValue) => {
    if (isFreeSquareBingo && !blockInUse && isActiveGame) {
      return true;
    }
    return markedNumbers.includes(blockValue);
  };

  return numbersMap.map((rows, rowIndex) => (
    <div
      className={clsx(styles.row, {
        [styles.isLongTicket]: isLongTicket,
        [styles.isLongTicketHistoryCard]: isLongTicketHistoryCard,
      })}
      key={`${id}-row-${rowIndex}`}
    >
      {rows.map((block, i) => (
        <NumberBlock
          key={`${id}-row-${rowIndex}-number-${block.Value || i + 100}-${
            winningNumbers ? winningNumbers.length : null
          }`}
          value={block.InUse ? block.Value : emptyBlock()}
          symbol={currentSymbol}
          symbolColorId={currentSymbolColorId}
          isActive={isActive(block.InUse, block.Value)}
          isHistory={isHistory}
          onClick={onClick}
          isAnimating={
            winningNumbers && winningNumbers.includes(Number(block.Value))
          }
          valueIsIcon={isFreeSquareBingo && !block.InUse}
        />
      ))}
    </div>
  ));
};

Numbers.propTypes = {
  id: oneOfType([string, number]).isRequired,
  numbersMap: arrayOf(arrayOf(object)).isRequired,
  onClick: func.isRequired,
  markedNumbers: arrayOf(number),
  currentSymbol: string,
  currentSymbolColorId: number,
  isHistory: bool,
  canAnimate: bool,
  isLongTicketHistoryCard: bool,
};

Numbers.defaultProps = {
  markedNumbers: [],
  currentSymbol: null,
  currentSymbolColorId: null,
  isHistory: false,
  canAnimate: false,
  isLongTicketHistoryCard: false,
};

// Only update if not equal.
const propsAreEqual = (prev, next) =>
  prev.markedNumbers.length === next.markedNumbers.length &&
  prev.currentSymbol === next.currentSymbol &&
  prev.currentSymbolColorId === next.currentSymbolColorId &&
  prev.isHistory === next.isHistory &&
  prev.isLongTicketHistoryCard === next.isLongTicketHistoryCard;

export default React.memo(Numbers, propsAreEqual);
