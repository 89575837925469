import React, { useState, useEffect } from 'react';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';

import text from 'polyglot/polyglot';
import clsx from 'clsx';
import shallow from 'zustand/shallow';
import useUserStore from 'stores/user';
import useCountdownStore from 'stores/countdown-state';
import useUpcomingGameStore from 'stores/upcoming-game';
import useCurrentGameStore from 'stores/current-game';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import MobileView from './MobileView/MobileView';
import DesktopView from './DesktopView/DesktopView';

import styles from './TopbarPanel.module.scss';

const userSelector = (state) => state.user.gameResponsibilityLink;
const hourSelector = (state) => state.setHours;
const minutesSelector = (state) => state.setMinutes;
const secondsSelector = (state) => state.setSeconds;
const upcomingGameSelector = (state) => state.nextGame;
const currentGameSelector = (state) => state.currentGame;
const startOfGameSelector = (state) => [
  state.startOfGame,
  state.setStartOfGame,
];
const activeCountdownGameSelector = (state) => [
  state.activeCountdownGame,
  state.setActiveGame,
];

const Topbar = () => {
  const breakpoint = useBreakpoint();
  const [modalVisible, setVisibility] = useState(false);
  const link = useUserStore(userSelector);
  const textObj = 'gamingResponsibility';
  const upcomingGame = useUpcomingGameStore(upcomingGameSelector);
  const currentGame = useCurrentGameStore(currentGameSelector);
  const [startOfGame, setStartOfGame] = useCountdownStore(
    startOfGameSelector,
    shallow
  );
  const [activeCountdownGame, setActiveGame] = useCountdownStore(
    activeCountdownGameSelector,
    shallow
  );
  const setHours = useCountdownStore(hourSelector);
  const setMinutes = useCountdownStore(minutesSelector);
  const setSeconds = useCountdownStore(secondsSelector);
  const [interval, updateInterval] = useState(null);
  const [clockIsStopped, stopCountdown] = useState(false);

  const modalVisibility = () => {
    setVisibility(!modalVisible);
  };

  useEffect(() => {
    if (!activeCountdownGame) {
      setActiveGame(currentGame);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  useEffect(() => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'sendEvent',
      eventCategory: 'Bingoklient',
      eventAction: 'Val av vy',
      eventLabel: 'Vy 1',
    });
  }, []);

  const countdown = () => {
    const now = new Date().getTime();
    const timeleft = startOfGame - now;
    if (startOfGame) {
      let remainingHours = String(
        Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      if (remainingHours.length === 1) {
        remainingHours = `0${remainingHours}`;
      }
      let remainingMinutes = String(
        Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
      );
      if (remainingMinutes.length === 1) {
        remainingMinutes = `0${remainingMinutes}`;
      }
      let remainingSeconds = String(
        Math.floor((timeleft % (1000 * 60)) / 1000)
      );
      if (remainingSeconds.length === 1) {
        remainingSeconds = `0${remainingSeconds}`;
      }
      if (now > startOfGame) {
        stopCountdown(true);
      } else {
        setHours(remainingHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    }
  };

  useEffect(() => {
    if (startOfGame) {
      updateInterval(setInterval(countdown, 1000));
      return () => updateInterval(clearInterval(interval));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOfGame]);

  useEffect(() => {
    if (currentGame && !currentGame.isDrawing) {
      if (!startOfGame) {
        const millisecondsUntilGameStarts = currentGame.startsInSeconds * 1000;
        setStartOfGame(new Date().getTime() + millisecondsUntilGameStarts);
      }
      if (
        activeCountdownGame &&
        activeCountdownGame.bingoRoundId !== currentGame.bingoRoundId
      ) {
        const millisecondsUntilGameStarts = currentGame.startsInSeconds * 1000;
        setStartOfGame(new Date().getTime() + millisecondsUntilGameStarts);
        setActiveGame(currentGame);
      }
    } else if (upcomingGame && upcomingGame.startsInSeconds) {
      if (!startOfGame) {
        const millisecondsUntilGameStarts = upcomingGame.startsInSeconds * 1000;
        setStartOfGame(new Date().getTime() + millisecondsUntilGameStarts);
      }
      if (
        activeCountdownGame &&
        activeCountdownGame.bingoRoundId !== upcomingGame.bingoRoundId
      ) {
        const millisecondsUntilGameStarts = upcomingGame.startsInSeconds * 1000;
        setStartOfGame(new Date().getTime() + millisecondsUntilGameStarts);
        setActiveGame(upcomingGame);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingGame, currentGame]);

  useEffect(() => {
    if (clockIsStopped) {
      updateInterval(clearInterval(interval));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockIsStopped]);

  return (
    <>
      {modalVisible && (
        <Modal>
          <Button
            className={styles.closeButton}
            onClick={modalVisibility}
            isModalCloseButton
          />
          <div className={styles.modalWrapper}>
            <Button
              className={styles.closeButtonMobile}
              onClick={modalVisibility}
              isModalCloseButton
            />
            <Scrollbars className={styles.scroll}>
              <p className={styles.heading1}>{text.t(`${textObj}.heading1`)}</p>
              <p className={styles.preamble}>{text.t(`${textObj}.preamble`)}</p>
              <p className={styles.content}>{text.t(`${textObj}.content1`)}</p>
              <p className={styles.heading2}>{text.t(`${textObj}.heading2`)}</p>
              <p className={styles.content}>{text.t(`${textObj}.content2`)}</p>
              <div
                className={clsx(styles.heading2, styles.link)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: link,
                }}
              />
            </Scrollbars>
            <div className={styles.gradient} />
          </div>
        </Modal>
      )}
      {breakpoint === DESKTOP ? (
        <DesktopView modalVisibility={modalVisibility} />
      ) : (
        <MobileView modalVisibility={modalVisibility} />
      )}
    </>
  );
};

export default Topbar;
