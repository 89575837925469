import React, { forwardRef, useEffect } from 'react';
import { string, bool, func } from 'prop-types';
import clsx from 'clsx';
import text from 'polyglot/polyglot';

import useChatTextStore from 'stores/chat-text';

import hasEmojiSupport from 'utils/hasEmojiSupport';

import TriangleIcon from 'assets/svg/triangle-shape.svg';

import EmojiKeyboard from './EmojiKeyboard/EmojiKeyboard';

import styles from './InputField.module.scss';

const hasEmojiKeyboard = hasEmojiSupport();

const isFocusedSelector = (state) => [state.isFocused, state.setIsFocused];

const InputField = forwardRef(
  (
    {
      value,
      isDisabled,
      isSending,
      onChange,
      onSubmit,
      chatIsClosed,
      anonymousUserFunc,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useChatTextStore(isFocusedSelector);

    const onSelectEmoji = (e) => {
      const emoji = e.currentTarget.innerText ?? null;
      const prevValue = ref.current.value;

      if (emoji) {
        const event = {
          target: {
            value: `${prevValue}${emoji}`,
          },
        };

        onChange(event);
      }
    };

    useEffect(() => {
      if (isFocused) {
        ref.current.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocused]);

    return (
      <form className={styles.form} onSubmit={onSubmit}>
        {hasEmojiKeyboard && <EmojiKeyboard onSelect={onSelectEmoji} />}
        {anonymousUserFunc && (
          <button
            type="button"
            className={styles.loginChatButton}
            onClick={anonymousUserFunc}
          >
            {text.t('chat.loginMessage')}
          </button>
        )}
        <input
          type="text"
          ref={ref}
          value={value}
          onChange={onChange}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          className={clsx(styles.input, {
            [styles.isDisabled]: isDisabled,
            [styles.isSending]: isSending,
          })}
          disabled={isDisabled || chatIsClosed}
          placeholder={chatIsClosed ? text.t('chat.isClosedMessage') : ''}
        />

        <button
          type="submit"
          className={styles.button}
          disabled={isDisabled || !value}
        >
          <TriangleIcon
            className={styles.icon}
            width={null}
            height={null}
            viewBox="0 0 30 29"
          />
        </button>
      </form>
    );
  }
);

InputField.propTypes = {
  value: string,
  isDisabled: bool,
  isSending: bool,
  onSubmit: func,
  onChange: func,
  chatIsClosed: bool,
  anonymousUserFunc: func,
};

InputField.defaultProps = {
  value: '',
  isDisabled: false,
  isSending: false,
  onSubmit: () => {},
  onChange: () => {},
  chatIsClosed: false,
  anonymousUserFunc: null,
};

const propsAreEqual = (prev, next) =>
  prev.value === next.value &&
  prev.isDisabled === next.isDisabled &&
  prev.isSending === next.isSending;

export default React.memo(InputField, propsAreEqual);
