import React, { useRef, useLayoutEffect, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { bool } from 'prop-types';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import usePrizePlanStore from 'stores/prizeplan-modal';
import useCurrentGameStore from 'stores/current-game';
import useMenuStore from 'stores/menu';
import useGameStore, { GAME_REPLAY, PURCHASE_VIEW } from 'stores/game';
import useBreakpoint, {
  DESKTOP,
  MOBILE,
  TABLET_LANDSCAPE,
} from 'hooks/useBreakpoint';
import tutorialStore from 'stores/tutorial';
import usePurchaseStore from 'stores/purchase';

import Button from 'components/Button/Button';
import PrizePlan from 'components/PrizePlan/PrizePlan';

import Star from 'assets/svg/gold-star.svg';
import infoIcon from 'assets/theme/svg/info.svg';

import styles from './GameInfoBar.module.scss';

const currentGameSelector = (state) => state.currentGame;
const setModalVisibilitySelector = (state) => state.setModalVisibility;
const gameStateSelector = (state) => state.gameState;
const tutorialDialoguePositionSelector = (state) => [
  state.tutorialDialoguePosition,
  state.setTutorialDialoguePosition,
];
const bingoCardsListSelector = (state) => state.bingoCardsList;
const menuOpenSelector = (state) => state.isMenuOpen;

const GameInfoBar = ({ isPartOfTutorial }) => {
  const modalIsVisible = usePrizePlanStore((state) => state.modalIsVisible);
  const setModalVisibility = usePrizePlanStore(setModalVisibilitySelector);
  const currentGame = useCurrentGameStore(currentGameSelector);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === MOBILE;
  const isDesktop = breakpoint === DESKTOP;
  const isTabletLandscape = breakpoint === TABLET_LANDSCAPE;
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;
  const displayJackpot =
    currentGame &&
    currentGame.jackpots.length &&
    currentGame.jackpots[0].amount !== 0;
  const isPurchase = gameState === PURCHASE_VIEW;

  const [tutorialDialoguePosition, setTutorialDialoguePosition] = tutorialStore(
    tutorialDialoguePositionSelector,
    shallow
  );
  const bingoCardsList = usePurchaseStore(bingoCardsListSelector, shallow);
  const isSideMenuActive = useMenuStore(menuOpenSelector);

  const gameInfoIconReference = useRef(0);
  const gameInfoWidthReference = useRef(0);

  function updatePosition() {
    if (!isPartOfTutorial && !isReplay) {
      setTutorialDialoguePosition({
        gameInfoBar: gameInfoWidthReference.current.getBoundingClientRect(),
        gameInfoIcon: gameInfoIconReference.current.getBoundingClientRect(),
      });
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bingoCardsList, isPartOfTutorial]);

  useEffect(() => {
    if (breakpoint !== DESKTOP) {
      setTimeout(() => {
        updatePosition();
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideMenuActive]);

  useEffect(() => {
    setTimeout(() => {
      updatePosition();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoint]);

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  return (
    <div
      ref={gameInfoWidthReference}
      className={clsx(styles.wrapper, {
        [styles.isPurchase]: isMobile && isPurchase,
        [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
      })}
      style={
        isPartOfTutorial &&
        tutorialDialoguePosition.gameInfoBar &&
        isTabletLandscape
          ? {
              width: tutorialDialoguePosition.gameInfoBar.width,
              marginLeft: tutorialDialoguePosition.gameInfoBar.left,
            }
          : {}
      }
    >
      {modalIsVisible && <PrizePlan closeModal={setModalVisibility} />}
      <div
        className={styles.innerWrapper}
        onClick={
          !isDesktop && !modalIsVisible && !isReplay && !isPartOfTutorial
            ? setModalVisibility
            : () => {}
        }
        role="button"
        tabIndex={0}
        onKeyPress={
          !isDesktop && !modalIsVisible && !isReplay && !isPartOfTutorial
            ? setModalVisibility
            : () => {}
        }
      >
        {!isReplay && (
          <Button
            isIcon
            className={clsx({
              [styles.tutorialIcon]: isPartOfTutorial,
            })}
            onClick={!isPartOfTutorial ? setModalVisibility : () => {}}
          >
            <img
              ref={gameInfoIconReference}
              src={infoIcon}
              alt=""
              className={styles.info}
            />
          </Button>
        )}
        <div className={styles.column}>
          <span>{text.t('gameInfo.price')}</span>
          <span className={styles.bold}>{`${
            currentGame.cardPrice ? currentGame.cardPrice : 0
          } kr`}</span>
        </div>
        <div className={clsx(styles.column, styles.visibleInMobile)}>
          <span>{text.t('gameInfo.numberOfPlayersText')}</span>
          <span className={styles.bold}>
            {text.t('gameInfo.numberOfPlayers', {
              amount: currentGame.numberOfPlayers
                ? currentGame.numberOfPlayers
                : 0,
            })}
          </span>
        </div>
        <div
          className={clsx(
            styles.column,
            styles.visibleInMobile,
            styles.firstColumn
          )}
        >
          <span>{text.t('gameInfo.totalPott')}</span>
          <span className={styles.bold}>
            {currentGame.pot
              ? new Intl.NumberFormat(
                  text.t('currencyLocale'),
                  settings
                ).format(currentGame.pot)
              : '0 kr'}
          </span>
        </div>
        {!isReplay && displayJackpot ? (
          <div className={clsx(styles.column, styles.visibleInMobile)}>
            <Star className={clsx(styles.star, styles.smallest)} />
            <Star className={clsx(styles.star, styles.smaller)} />
            <Star className={styles.star} />
            <span className={styles.gold}>{text.t('gameInfo.jackpott')}</span>
            <span className={clsx(styles.bold, styles.gold)}>
              {currentGame.jackpots[0].jackpotValueDisplayName
                ? currentGame.jackpots[0].jackpotValueDisplayName
                : new Intl.NumberFormat(
                    text.t('currencyLocale'),
                    settings
                  ).format(currentGame.jackpots[0].amount)}
            </span>
          </div>
        ) : null}
        {!isReplay && displayJackpot ? (
          <div className={clsx(styles.column, styles.visibleInMobile)}>
            <span className={styles.gold}>{text.t('gameInfo.withinText')}</span>
            <span
              className={clsx(
                styles.bold,
                styles.gold,
                styles.smallerMarginLeft
              )}
            >
              {text.t('gameInfo.withinBalls', {
                amount: currentGame.jackpots[0].triggerNumberOfDraws,
              })}
            </span>
            <Star className={styles.star} />
            <Star className={clsx(styles.star, styles.smaller)} />
            <Star className={clsx(styles.star, styles.smallest)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

GameInfoBar.propTypes = {
  isPartOfTutorial: bool,
};

GameInfoBar.defaultProps = {
  isPartOfTutorial: false,
};

export default GameInfoBar;
