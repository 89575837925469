import create from 'zustand';

const useChatTextStore = create((set) => ({
  isFocused: false,
  setIsFocused: (value) => set({ isFocused: value }),
  isWritten: '',
  setIsWritten: (value) => set({ isWritten: value }),
  groupChatIsClosed: false,
  setGroupChatIsClosed: (value) => set(() => ({ groupChatIsClosed: value })),
  userIsBlocked: false,
  setUserIsBlocked: (value) => set(() => ({ userIsBlocked: value })),
}));

export default useChatTextStore;
