import React, { useEffect, useState, useRef } from 'react';
import { number } from 'prop-types';
import clsx from 'clsx';
import { useTransition, animated } from 'react-spring';
import useBreakpoint, {
  DESKTOP,
  TABLET_PORTRAIT,
  TABLET_LANDSCAPE,
  MOBILE,
} from 'hooks/useBreakpoint';

import text from 'polyglot/polyglot';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useLeaderboardStore from 'stores/leaderboard';
import { getUserProfileImg } from 'utils/getUserProfileImg';
import useCardRankingStore from 'stores/card-ranking';

import Separator from 'assets/svg/separator.svg';

import styles from './Leaderboard.module.scss';

const leaderboardSelector = (state) => [state.leaderboard];
const userSelector = (state) => state.user;
const rankingSelector = (state) => state.cardRanking;
const userAvatarSelector = (state) => state.settings.userAvatar;

const LeaderBoard = ({ leaderboardLength }) => {
  const [leaderboardList] = useLeaderboardStore(leaderboardSelector);
  const ranking = useCardRankingStore(rankingSelector);
  const [currentUserPlacement, setPlacement] = useState(null);
  const user = useUserStore(userSelector);
  const userAvatar = useSettingsStore(userAvatarSelector);

  const breakpoint = useBreakpoint();

  const listHeight = useRef(null);
  const itemHeight = {
    [DESKTOP]: 26,
    [TABLET_PORTRAIT]: 24,
    [TABLET_LANDSCAPE]: 24,
    [MOBILE]: 20,
  };
  const height = {
    [DESKTOP]: 30,
    [TABLET_PORTRAIT]: 28,
    [TABLET_LANDSCAPE]: 28,
    [MOBILE]: 25,
  };

  const userItemHeight = itemHeight[breakpoint];
  const listHeightItem = height[breakpoint];

  const placementOfUser = () => {
    if (!user.isAuthenticated || !ranking.length || !leaderboardList.length) {
      return null;
    }
    let isUserInBoard = -1;
    leaderboardList.forEach((player, index) => {
      if (player.name === user.nickname && index < leaderboardLength) {
        isUserInBoard = index;
      }
    });
    return isUserInBoard;
  };

  useEffect(() => {
    setPlacement(placementOfUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboardList]);

  const returnBingoBalls = (balls) => {
    let total = balls;
    if (balls > 5) {
      total = 5;
    }
    const content = [];
    for (let i = 0; i < total; i++) {
      content.push(
        <div
          style={{ zIndex: `${total - i}` }}
          key={i + total}
          className={styles.bingoBall}
        />
      );
    }
    return content;
  };

  const listTransitions = useTransition(
    leaderboardList.map((item, index) => {
      if (index === 0) {
        listHeight.current = 0;
      }
      if (
        currentUserPlacement === -1
          ? index < leaderboardLength - 1
          : index < leaderboardLength
      ) {
        /* listHeight += item height + item margin-bottom */
        listHeight.current += listHeightItem;
      }

      return {
        ...item,
        translateY: listHeight.current - userItemHeight,
      };
    }),
    (item) => item.name,
    {
      from: { opacity: 0, height: 0 },
      leave: { opacity: 0, height: 0 },
      enter: ({ translateY }) => ({
        translateY,
        height: userItemHeight,
        opacity: 1,
      }),
      update: ({ translateY }) => ({ translateY, height: userItemHeight }),
    }
  );

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
      })}
    >
      <div className={styles.headerWrapper}>
        <span className={styles.header}>{text.t('leaderboard.heading')}</span>
      </div>
      <div
        className={styles.leaderboard}
        key={leaderboardList}
        style={{ minHeight: listHeight.current }}
      >
        {leaderboardList &&
          listTransitions.map(
            ({ item, props: { translateY, ...rest }, key }, index) => {
              const actualLength =
                currentUserPlacement === -1
                  ? leaderboardLength - 1
                  : leaderboardLength;

              if (index < actualLength) {
                return (
                  <animated.div
                    className={styles.user}
                    key={key}
                    style={{
                      transform: translateY.interpolate(
                        (y) => `translate3d(0,${y}px,0)`
                      ),
                      zIndex: leaderboardList.length + 1 - index,
                      ...rest,
                    }}
                  >
                    <img
                      src={getUserProfileImg(item.avatarId)}
                      alt=""
                      className={styles.icon}
                    />
                    <span className={styles.username}>{item.name}</span>
                    <div className={styles.ballsLeftWrapper}>
                      {item.numberOfNumbersToWin === 1 ? (
                        <>
                          <span>
                            {item.winningNumbers.map((nr, i) =>
                              i + 1 === item.winningNumbers.length
                                ? ` ${nr}`
                                : ` ${nr},`
                            )}
                          </span>
                          <div
                            className={clsx(styles.bingoBall, styles.single)}
                          />
                        </>
                      ) : (
                        <>{returnBingoBalls(item.numberOfNumbersToWin)}</>
                      )}
                    </div>
                  </animated.div>
                );
              }
              return null;
            }
          )}
      </div>
      {currentUserPlacement !== null && currentUserPlacement === -1 ? (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {leaderboardLength !== 1 && (
            <Separator className={styles.separator} />
          )}
          <div className={clsx(styles.user, styles.currentUser)}>
            <img
              src={getUserProfileImg(userAvatar)}
              alt=""
              className={styles.icon}
            />
            <span className={styles.username}>{user.nickname}</span>
            <div className={styles.ballsLeftWrapper}>
              {ranking.length && (
                <>
                  {ranking[0].numberOfNumbersToWin === 1 ? (
                    <>
                      <span>
                        {ranking[0].winningNumbers.map((nr, i) =>
                          i + 1 === ranking[0].winningNumbers.length
                            ? ` ${nr}`
                            : ` ${nr},`
                        )}
                      </span>
                      <div className={clsx(styles.bingoBall, styles.single)} />
                    </>
                  ) : (
                    <>{returnBingoBalls(ranking[0].numberOfNumbersToWin)}</>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

LeaderBoard.propTypes = {
  leaderboardLength: number,
};

LeaderBoard.defaultProps = {
  leaderboardLength: 5,
};
export default LeaderBoard;
