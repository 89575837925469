const hasEmojiSupport = () => {
  const node = document.createElement('canvas');
  const ctx = node.getContext('2d');
  const backingStoreRatio =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;
  const offset = 12 * backingStoreRatio;

  ctx.fillStyle = '#f00';
  ctx.textBaseline = 'top';
  ctx.font = '32px Arial';
  ctx.fillText('\ud83d\udc28', 0, 0); // U+1F428 KOALA

  return ctx.getImageData(offset, offset, 1, 1).data[0] !== 0;
};

export default hasEmojiSupport;
